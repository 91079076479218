import React from "react";
import TemplateCard, { DevdocuTemplateType } from "../Components/TemplateCard";
import { Button, useAccordion } from "@nextui-org/react";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";

const mockTemplates: DevdocuTemplateType[] = [
  {
    title: "Mock",
    description: "Mock description",
    level: "basic",
    githubLink: "https://github.com",
  },
  {
    title: "Foo",
    description:
      "Mock description, bar bar foo! React JS, Redux JS, Angular JS",
    level: "basic",
    githubLink: "https://github.com",
  },
  {
    title: "Bar",
    description:
      "Mock description, OSIRIS-REx has returned. The SRC has been retrieved.",
    level: "basic",
    githubLink: "https://github.com",
  },
];

type Props = { templates?: DevdocuTemplateType[] };

export default function TemplatesSection({ templates = mockTemplates }: Props) {

  const handleOpenModal = useSelector((state: any)=>{return state.rootReducer.appReducer.handleOpenModal})

  return (
    <>
      <div className="w-full flex flex-row justify-center pb-3">
        <Button
          onClick={() => {
            handleOpenModal("search", ["template"]);
          }}
          className=""
        >
          <FaSearch className="" />
          <div className="">Search Templates</div>
        </Button>
      </div>
      <div className=" grid-cols-2 lg:grid-cols-4 md:grid-cols-3 grid gap-3 w-full z-[20] sticky transition-all">
        {templates.map((template) => {
          return <TemplateCard template={template} />;
        })}
      </div>
    </>
  );
}
