import { MarkerType, Position } from "reactflow";
import { DataRoute } from "../General/types";
import RtkApplication from "./RtkApplication";

export const rtkMainNodes = [
  {
    id: "application",
    position: { x: 10, y: 300 },
    type: "customNode",
    data: {
      value: "application",
      description: "",
      sourcePosition: Position.Right,
      targetPosition: Position.Top,
      type: "app",
      payload: RtkApplication,
      isMovable: true,
      isClickable: true,
    },
    dragHandle: ".drag-handle",
  },
  {
    id: "dispatch",
    type: "customNode",
    position: { x: 600, y: 500 },
    data: {
      value: "dispatch",
      description: "",
      sourcePosition: Position.Top,
      targetPosition: Position.Left,
      type: "information",
      payload: {
        description:
          "TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST ",
        code: "console.log();\nuseEffect(()=>{}, []);\nconst foo = (state: any) => {\n    return state.bar;\n}",
      },
      isMovable: true,
      isClickable: true,
      emoji: '📢',
    },
  },
  {
    id: "reducer",
    type: "customNode",
    position: { x: 600, y: 0 },
    data: {
      value: "reducer",
      description: "",
      sourcePosition: Position.Left,
      targetPosition: Position.Bottom,
      type: "information",
      payload: {
        description:
          "TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST ",
        code: "console.log();\nuseEffect(()=>{}, []);\nconst foo = (state: any) => {\n    return state.bar;\n}",
      },
      isMovable: true,
      isClickable: true,
      emoji: '💻',
    },
  },
  {
    id: "store",
    type: "customNode",
    position: { x: 100, y: 0 },
    data: {
      value: "store",
      description: "",
      sourcePosition: Position.Left,
      targetPosition: Position.Right,
      type: "information",
      payload: {
        description:
          "TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST ",
        code: "console.log();\nuseEffect(()=>{}, []);\nconst foo = (state: any) => {\n    return state.bar;\n}",
      },
      isMovable: true,
      isClickable: true,
      emoji: '💾',
    },
  },
];

export const rtkMainEdges = [
  {
    id: "application_to_dispatch",
    source: "application",
    target: "dispatch",
    type: "buttonedge",
    data: {
      popUpLocation: "middle",
      origin: true,
      payload: {
        description:
          "TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST ",
        code: "console.log();\nuseEffect(()=>{}, []);\nconst foo = (state: any) => {\n    return state.bar;\n}",
      },
      showButton: true,
    },
  },
  {
    id: "dispatch_to_reducer",
    source: "dispatch",
    target: "reducer",
    type: "buttonedge",
    data: {
      popUpLocation: "middle",
      payload: {
        description:
          "TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST ",
        code: "console.log();\nuseEffect(()=>{}, []);\nconst foo = (state: any) => {\n    return state.bar;\n}",
      },
      showButton: true,
    },
  },
  {
    id: "reducer_to_store",
    source: "reducer",
    target: "store",
    type: "buttonedge",
    data: {
      popUpLocation: "middle",
      payload: {
        description:
          "TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST ",
        code: "console.log();\nuseEffect(()=>{}, []);\nconst foo = (state: any) => {\n    return state.bar;\n}",
      },
      showButton: true,
    },
  },
  {
    id: "store_to_application",
    source: "store",
    target: "application",
    type: "buttonedge",
    data: {
      popUpLocation: "middle",
      payload: {
        description:
          "TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST ",
        code: "console.log();\nuseEffect(()=>{}, []);\nconst foo = (state: any) => {\n    return state.bar;\n}",
      },
      showButton: true,
    },
    markerEnd: "devdocuArrow",
  },
];

export const addNewItemRoute: DataRoute = {
  id: "add-new-item",
  callerId: "rtk-main-add-button",
  description: "Add a new item to the Redux snippet",
  dataRoute: [
    {
      path: { from: { id: "application" }, to: { id: "dispatch" } },
      orderNumber: 0,
    },
    {
      path: { from: { id: "dispatch" }, to: { id: "reducer" } },
      orderNumber: 1,
    },
    { path: { from: { id: "reducer" }, to: { id: "store" } }, orderNumber: 2 },
    {
      path: { from: { id: "store" }, to: { id: "application" } },
      orderNumber: 3,
    },
  ],
};

export const rtkDataRoutes: DataRoute[] = [addNewItemRoute];
