import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ScreenSize } from '../General/types';
import { DataRoute } from '../General/types';
import { ScreenWidth } from '../General/sizes';

// Slice for application state management for Devdocu

interface AppState {
    screenSize: ScreenSize[]
    highlightedSection: string | null
    dataRoutes: DataRoute[]
    activeDataRoute: string;
    changesDetected: boolean;
    showBeginnerReminder: boolean;
    pictureInPicture: boolean;
    resizingMainGraph: boolean;
    screenWidth: ScreenWidth;
    changeCount: number;
    handleOpenModal: any;
}

const initialState: AppState = {
  screenSize: [ScreenSize.Desktop],
  highlightedSection: '',
  dataRoutes: [],
  activeDataRoute: '',
  changesDetected: false,
  showBeginnerReminder: true,
  pictureInPicture: false,
  resizingMainGraph: false,
  screenWidth: ScreenWidth.SM,
  changeCount: 0,
  handleOpenModal: null,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setScreenSize: (state, action) => {
        state.screenSize = action.payload; 
    },
    setHighlightedSection: (state, action) => {
      state.highlightedSection = action.payload;
    },
    setDataRoutes: (state, action) => {
      state.dataRoutes = action.payload
    },
    addDataRoute: (state, action) => {
      state.dataRoutes.push(action.payload)
    },
    clearDataRoutes: (state) => {
      state.dataRoutes = []
    },
    setActiveDataRoute: (state, action) => {
      state.activeDataRoute = action.payload
    },
    setChangesDetected: (state, action) => {
      state.changesDetected = action.payload
    },
    setShowBeginnerReminder: (state, action) => {
      state.showBeginnerReminder = action.payload
    },
    setPictureInPicture: (state, action) => {
      state.pictureInPicture = action.payload
    },
    setResizingMainGraph: (state, action) => {
      state.resizingMainGraph = action.payload
    },
    setScreenWidth: (state, action) => {
      state.screenWidth = action.payload
    },
    incrementChangeCount: (state) => {
      state.changeCount++;
    },
    setHandleOpenModal: (state, action) => {
      state.handleOpenModal = action.payload
    }
  },
});

export const { setScreenSize, setHandleOpenModal, setHighlightedSection, incrementChangeCount, setDataRoutes, addDataRoute, setPictureInPicture, clearDataRoutes, setScreenWidth, setActiveDataRoute, setResizingMainGraph, setChangesDetected, setShowBeginnerReminder } = appSlice.actions;
export default appSlice.reducer;
