import {
    Button,
    Checkbox,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    Progress,
    useDisclosure,
  } from "@nextui-org/react";
  import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowBeginnerReminder } from "../Redux/appSlice";
  
  type Props = {};
  
  export default function BeginnerReminder({}: Props) {

    //TODO remove unneeded store state for showBeginnerReminder, use localStorage instead
    const dispatch = useDispatch();
    const show = useSelector((state: any)=>state.rootReducer.appReducer.showBeginnerReminder)

    const { isOpen, onOpen, onClose } = useDisclosure();
  
    const [value, setValue] = useState(0);
    
    const showReminder = localStorage.getItem("showBeginnerReminder") || "show";
    localStorage.setItem("showBeginnerReminder", showReminder);

  
    useEffect(() => {
        if(localStorage.getItem("showBeginnerReminder") === "show") {
            onOpen();
        }
    }, []);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setValue((v) => {
          if (v >= 100) {
            clearInterval(interval); // Stop the interval when value reaches 100
            onClose(); // Close the modal when the value reaches 100
            return v; // Return the current value
          } else {
            return v + 1; // Increment the value
          }
        });
      }, 100);
      return () => {
        clearInterval(interval); // Clean up the interval on component unmount
      };
    }, [onClose]);
  
    function handleOnCheckboxChange(event: ChangeEvent<HTMLInputElement>): void {
      localStorage.setItem("showBeginnerReminder", event.target.checked ? "hide" : "show")
    }

    return (
      <Modal
        isOpen={isOpen}
        onOpenChange={() => {}}
        placement="bottom"
        backdrop="blur"
        size="xs"
        className="dark text-foreground"
        shouldBlockScroll = {true}
        closeButton={<></>}

      >
        <ModalContent className="text-[12px]" aria-label="">
          <ModalBody className="flex flex-col">
            <div>
              If you're a beginner in Redux Toolkit, it's highly recommended to
              first visit our guide.
            </div>
            <Checkbox size="sm" onChange={handleOnCheckboxChange} aria-label="dont-show-again">
                <div className="text-[10px]">Don't show again</div>
            </Checkbox>
            <Progress size="sm" color="success" className="max-w-md" value={value} aria-label="close-reminder"/>
            <div className="flex flex-row justify-center gap-3">
              <Button size="sm" onClick={onClose} color="danger">
                Close
              </Button>
              <Link href="/reduxtoolkit-introduction">
              <Button size="sm" color="primary">
                Guide
              </Button>
              </Link>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
  