import React from 'react';
import JSZip from 'jszip';
import { mockIndex } from '../Mock/MockSearchIndex';

// Given a JSON array, zip it and download it.
export const JSONZipDownloadButton: React.FC = () => {
  const separateAndZipJSONObjects = () => {
    const jsonArray = mockIndex

    const zip = new JSZip();

    jsonArray.forEach((jsonObj, index) => {
      const jsonFileName = `object_${index + 1}.json`;
      const jsonData = JSON.stringify(jsonObj, null, 2); // Convert JSON object to string with formatting
      zip.file(jsonFileName, jsonData);
    });

    return zip;
  };

  const handleDownload = () => {
    const zip = separateAndZipJSONObjects();

    zip.generateAsync({ type: "blob" }).then(function (content) {
      // Create a download link
      const link = document.createElement("a");
      link.download = "json_objects.zip";
      link.href = URL.createObjectURL(content);

      // Trigger the download
      link.click();
    });
  };

  return (
    <div>
      <button onClick={handleDownload}>Download JSON Zip</button>
    </div>
  );
};

export default JSONZipDownloadButton;
