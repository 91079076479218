import { Position } from "reactflow";
import { rtkMainEdges, rtkMainNodes } from "./rtkmainGraphData";
import RtkApplication from "./RtkApplication";
import {
  SubheaderDescriptorType,
  SubheaderDescriptorTypes,
} from "../Components/Subheader";
import { InformationSectionTypes } from "../General/types";

export enum DevdocuTypes {
  DEVDOCU_CODESNIPPET = "devdocu_codesnippet",
  DEVDOCU_POPOVER = "devdocu_popover",
  DEVDOCU_NORMALTEXT = "devdocu_normaltext",
  DEVDOCU_HEADER = "devdocu_header",
  DEVDOCU_INFORMATIONPARAGRAPH = "devdocu_informationparagraph",
  DEVDOCU_DEFAULTPARAGRAPH = "devdocu_defaultparagraph",
  DEVDOCU_SECTIONTYPE = "devdocu_sectiontype",
  DEVDOCU_DOCUMENTATIONTYPE = "devdocu_documentationtype",
  DEVDOCU_TAG = "devdocu_tag",
  DEVDOCU_DOCUMENTATIONDIVIDER = "devdocu_documentationdivider",
  DEVDOCU_SMALLGRAPH = "devdocu_smallgraph",
}

export type DevdocuSmallGraph = {
  type: DevdocuTypes.DEVDOCU_SMALLGRAPH;
  nodes: any[]; //TODO set type
  edges: any[]; //TODO set type
};

//Code snippet
export type DevdocuCodeSnippet = {
  type: DevdocuTypes.DEVDOCU_CODESNIPPET;
  code: string;
};
//Popover type
export type DevdocuPopover = {
  type: DevdocuTypes.DEVDOCU_POPOVER;
  text: string;
  popoverText: string;
};
//Normal text type
export type DevdocuNormalText = {
  type: DevdocuTypes.DEVDOCU_NORMALTEXT;
  text: string;
};
//Header type
export type DevdocuHeader = {
  type: DevdocuTypes.DEVDOCU_HEADER;
  header: string;
  descriptors?: SubheaderDescriptorType[];
};
//Devdocu paragraph
export type DevdocuParagraph = {
  title?: string;
  content: (DevdocuNormalText | DevdocuPopover)[];
  type:
    | DevdocuTypes.DEVDOCU_INFORMATIONPARAGRAPH
    | DevdocuTypes.DEVDOCU_DEFAULTPARAGRAPH;
  infotype?: InformationSectionTypes;
};
//Devdocu section
export type DevdocuSectionType = {
  type: DevdocuTypes.DEVDOCU_SECTIONTYPE;
  title: string;
  sectionName: string;
  sectionGroup: string;
  content: (
    | DevdocuParagraph
    | DevdocuCodeSnippet
    | DevdocuHeader
    | DevdocuSmallGraph
  )[];
  tags?: string[];
  project?: string;
};
//Devdocu documentation divider
export type DevdocuDocumentationDivider = {
  type: DevdocuTypes.DEVDOCU_DOCUMENTATIONDIVIDER;
};
//Devdocu documentation page
export type DevdocuDocumentationPage = {
  type: DevdocuTypes.DEVDOCU_DOCUMENTATIONTYPE;
  title: string;
  lastUpdated: string;
  tags: DevdocuTag[];
  content: (DevdocuSectionType | DevdocuDocumentationDivider)[];
  project?: string;
};

export type DevdocuTag = {
  type: DevdocuTypes.DEVDOCU_TAG;
  tag: string;
};

const rtkTags = [
  "redux",
  "react",
  "redux toolkit",
  "rtk",
  "state management",
  "front end",
];
const rtkProjectName = "redux toolkit";

const motivationSection: DevdocuSectionType = {
  type: DevdocuTypes.DEVDOCU_SECTIONTYPE,
  title: "Motivation",
  sectionName: "motivation",
  sectionGroup: "guide",
  tags: [...rtkTags, "introduction", "start", "quick start"],
  project: rtkProjectName,
  content: [
    {
      content: [
        {
          type: DevdocuTypes.DEVDOCU_NORMALTEXT,
          text: "You are coming in from ",
        },
        {
          type: DevdocuTypes.DEVDOCU_POPOVER,
          text: "React",
          popoverText: "FOO",
        },
        {
          type: DevdocuTypes.DEVDOCU_NORMALTEXT,
          text: ". ",
        },
        {
          type: DevdocuTypes.DEVDOCU_POPOVER,
          text: "State management",
          popoverText: "FOO",
        },
        {
          type: DevdocuTypes.DEVDOCU_NORMALTEXT,
          text: "can be hell. You don’t know which state does what anymore and you’re passing a million different <code>setState</code>s and <code>states</code> into each prop, making data management complex. <mark><b>Redux Toolkit</b> is a data storage system which is accessible from any component in your application</mark>, providing a solution to this problem.",
        },
      ],
      type: DevdocuTypes.DEVDOCU_DEFAULTPARAGRAPH,
    },
  ],
};

const importantNotesSection: DevdocuSectionType = {
  type: DevdocuTypes.DEVDOCU_SECTIONTYPE,
  title: "Important Notes",
  sectionName: "important-notes",
  sectionGroup: "guide",
  tags: [...rtkTags, "introduction", "start", "quick start"],
  project: rtkProjectName,
  content: [
    {
      content: [
        {
          type: DevdocuTypes.DEVDOCU_NORMALTEXT,
          text: "Before diving into Redux Toolkit, it is important to understand that <b>the fundamentals of Redux Toolkit comes from Redux JS</b>. <mark>Redux JS is  the fundamental of the entire system, with React-Redux being the extension of Redux JS better suited for React applications, and Redux Toolkit being the extension of React-Redux that comes with extra tools to aid development even further.</mark> Currently, <b>Redux Toolkit is the officially recommended framework to use with React</b>, but it’s still very beneficial to understand how Redux JS works fundamentally in order to fully understand Redux Toolkit.",
        },
      ],
      title: "Redux JS, Redux Toolkit?",
      type: DevdocuTypes.DEVDOCU_INFORMATIONPARAGRAPH,
    },
    {
      nodes: [
        {
          id: "reduxjs",
          position: { x: 0, y: 0 },
          type: "customNode",
          data: {
            value: "Redux JS",
            description: "",
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            type: "information",
            payload: RtkApplication,
            isMovable: false,
            isClickable: false,
          },
          dragHandle: ".drag-handle",
        },
        {
          id: "reactredux",
          position: { x: 300, y: 0 },
          type: "customNode",
          data: {
            value: "React-Redux",
            description: "",
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            type: "information",
            payload: RtkApplication,
            isMovable: false,
            isClickable: false,
          },
          dragHandle: ".drag-handle",
        },
        {
          id: "reduxtoolkit",
          position: { x: 600, y: 0 },
          type: "customNode",
          data: {
            value: "Redux Toolkit",
            description: "",
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            type: "information",
            payload: RtkApplication,
            isMovable: false,
            isClickable: false,
          },
          dragHandle: ".drag-handle",
        },
      ],
      edges: [
        {
          id: "rtkreducerslice_to_rtkreducerreducer",
          source: "reduxjs",
          target: "reactredux",
          type: "buttonedge",
          data: {
            popUpLocation: "middle",
            origin: true,
            payload: {
              description:
                "TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST ",
              code: "console.log();\nuseEffect(()=>{}, []);\nconst foo = (state: any) => {\n    return state.bar;\n}",
            },
            showButton: false,
          },
          markerEnd: "devdocuArrow",
        },
        {
          id: "rtkreducerslice_to_rtkreducerreducer",
          source: "reactredux",
          target: "reduxtoolkit",
          type: "buttonedge",
          data: {
            popUpLocation: "middle",
            origin: true,
            payload: {
              description:
                "TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST ",
              code: "console.log();\nuseEffect(()=>{}, []);\nconst foo = (state: any) => {\n    return state.bar;\n}",
            },
            showButton: false,
          },
          markerEnd: "devdocuArrow",
        },
      ],
      type: DevdocuTypes.DEVDOCU_SMALLGRAPH,
    },
  ],
};

const introductionSection: DevdocuSectionType = {
  title: "Introduction",
  sectionName: "introduction",
  sectionGroup: "guide",
  tags: [...rtkTags, "introduction", "start", "quick start"],
  content: [
    {
      content: [
        {
          type: DevdocuTypes.DEVDOCU_NORMALTEXT,
          text: "<b>Redux Toolkit keeps data in one central spot called the store</b>. Think of it like a mini database for your application. With it, you can easily make and track changes to your data without getting lost in the details. <b>Any changes to the store are done through a reducer.</b> additionally, Redux Toolkit comes with a variety of other different tools which can aid state management.",
        },
      ],
      type: DevdocuTypes.DEVDOCU_DEFAULTPARAGRAPH,
    },
  ],
  type: DevdocuTypes.DEVDOCU_SECTIONTYPE,
};

const storeSection: DevdocuSectionType = {
  title: "Store",
  sectionName: "store",
  sectionGroup: "documentation",
  tags: [...rtkTags, "reducer", "store", "data", "save"],
  project: rtkProjectName,
  content: [
    {
      content: [
        {
          type: DevdocuTypes.DEVDOCU_NORMALTEXT,
          text: "<mark>A store is a central place where your application's data (state) is stored</mark>. It acts as the single source of truth. An application typically only has one store. Updating any information in the store is done through what’s called a “Reducer”. The store is typically stored in a separate file",
        },
      ],
      type: DevdocuTypes.DEVDOCU_DEFAULTPARAGRAPH,
    },
    {
      header: "Creation",
      type: DevdocuTypes.DEVDOCU_HEADER,
      descriptors: [
        { type: SubheaderDescriptorTypes.CODE, content: "configureStore()" },
      ],
    },
    {
      content: [
        {
          type: DevdocuTypes.DEVDOCU_NORMALTEXT,
          text: "in RTK, the <b><code>configureStore()</code> function is used to create the store</b>, while specifying the reducers, preloadedState, and any further functionalities, such as middleware.",
        },
      ],
      type: DevdocuTypes.DEVDOCU_DEFAULTPARAGRAPH,
    },
    {
      header: "Connecting to the Application",
      type: DevdocuTypes.DEVDOCU_HEADER,
      descriptors: [
        { type: SubheaderDescriptorTypes.CODE, content: "<Provider>" },
      ],
    },
    {
      content: [
        {
          text: "In order to <b>connect the application to the store</b>, the <code>Provider</code> tag is used.",
          type: DevdocuTypes.DEVDOCU_NORMALTEXT,
        },
      ],
      type: DevdocuTypes.DEVDOCU_DEFAULTPARAGRAPH,
    },
    {
      header: "Getting Information",
      type: DevdocuTypes.DEVDOCU_HEADER,
      descriptors: [
        { type: SubheaderDescriptorTypes.CODE, content: "useSelector()" },
      ],
    },
    {
      content: [
        {
          text: "Getting information from the store can then be done using <code>useSelector()</code>.",
          type: DevdocuTypes.DEVDOCU_NORMALTEXT,
        },
      ],
      type: DevdocuTypes.DEVDOCU_DEFAULTPARAGRAPH,
    },
    {
      code: "useSelector(state: any=>state.rootReducer)",
      type: DevdocuTypes.DEVDOCU_CODESNIPPET,
    },
    {
      header: "Updating Information",
      type: DevdocuTypes.DEVDOCU_HEADER,
      descriptors: [
        { type: SubheaderDescriptorTypes.COMPONENT, content: "Reducer" }
      ]
    },
    {
      content: [
        {
          text: "<b>Updating the store’s data is done through a reducer</b>, which is attached to a store. <b>A reducer would receive an “Action”</b>, and then update the store based on the information it’s received. The reducer is typically specified when creating a store.",
          type: DevdocuTypes.DEVDOCU_NORMALTEXT,
        },
      ],
      type: DevdocuTypes.DEVDOCU_DEFAULTPARAGRAPH,
    },
    {
      content: [
        {
          type: DevdocuTypes.DEVDOCU_NORMALTEXT,
          text: "For example, a redux store could have two reducers called userReducer to handle user information, and an action could be sent out by a button that tells the reducer to set the user’s name, and a settingsReducer to handle the settings of the application, and an action could be sent out by another button that tells the reducer to toggle dark mode. ",
        },
      ],
      title: "Example",
      type: DevdocuTypes.DEVDOCU_INFORMATIONPARAGRAPH,
      infotype: InformationSectionTypes.ExampleSection
    },
    {
      content: [
        {
          type: DevdocuTypes.DEVDOCU_NORMALTEXT,
          text: "Redux enforces immutability. <b>Instead of modifying the current state directly, we create a new copy of the state with changes.</b> This makes state changes predictable. Reducers are responsible for this: they take the current state and an action, then return a new state without modifying the original one. ",
        },
      ],
      title: "Immutability",
      type: DevdocuTypes.DEVDOCU_INFORMATIONPARAGRAPH,
    },
  ],
  type: DevdocuTypes.DEVDOCU_SECTIONTYPE,
};

const reducerSection: DevdocuSectionType = {
  title: "Reducer",
  sectionName: "reducer",
  sectionGroup: "documentation",
  tags: [...rtkTags, "reducer", "store", "data", "update"],
  project: rtkProjectName,
  content: [
    {
      content: [
        {
          type: DevdocuTypes.DEVDOCU_NORMALTEXT,
          text: "The fundamental concept of using reducers comes from Redux JS. You may notice that there are two different upcoming terms: <b>Slice, and Reducer</b>. A Reducer is what’s responsible for updating the store. <b>A Redux slice is an abstraction that streamlines the process of compiling reducers, reducing the need for boilerplate code.</b> This is a feature of Redux Toolkit. The distinction of reducers and slices may overlap and may take some time to get used to.",
        },
      ],
      title: "Redux JS Fundamentals",
      type: DevdocuTypes.DEVDOCU_INFORMATIONPARAGRAPH,
    },
    {
      content: [
        {
          text: "<b>Reducers tell how changes to the data in the store are made.</b> They manage specific parts of the store, such as “users” or “shoppingCart”. In our example, the “users” reducer can accept an action with the type “addUser”. This action might be dispatched when a button in the UI is clicked, with the user's name in the “payload”. Traditionally in Redux, actions are created using action creators. Redux Toolkit introduced 'slices', which group the reducer logic and action creators for a specific segment of state, minimizing boilerplate by automating action creation and reducing function definitions.",
          type: DevdocuTypes.DEVDOCU_NORMALTEXT,
        },
      ],
      type: DevdocuTypes.DEVDOCU_DEFAULTPARAGRAPH,
    },
    {
      header: 'Creation',
      descriptors: [{ type: SubheaderDescriptorTypes.CODE, content: "createSlice()" }],
      type: DevdocuTypes.DEVDOCU_HEADER,
    },
    {
      content: [
        {
          type: DevdocuTypes.DEVDOCU_NORMALTEXT,
          text: "It helps to understand that slices are essentially just a combination of what was traditionally an action creator and a reducer.",
        },
      ],
      title: " ",
      type: DevdocuTypes.DEVDOCU_INFORMATIONPARAGRAPH,
    },
    {
      nodes: [
        {
          id: "rtkreducerreducer",
          position: { x: 0, y: 0 },
          type: "customNode",
          data: {
            value: "reducer",
            description: "",
            sourcePosition: Position.Right,
            targetPosition: Position.Right,
            type: "information",
            payload: RtkApplication,
            isMovable: false,
            isClickable: false,
            emoji: '📢',
          },
          dragHandle: ".drag-handle",
        },
        {
          id: "rtkreducerslice",
          position: { x: 200, y: 0 },
          type: "customNode",
          data: {
            value: "slice",
            description: "",
            sourcePosition: Position.Left,
            targetPosition: Position.Left,
            type: "information",
            payload: RtkApplication,
            isMovable: false,
            isClickable: false,
            emoji: '📢',
          },
          dragHandle: ".drag-handle",
        },
      ],
      edges: [
        {
          id: "rtkreducerslice_to_rtkreducerreducer",
          source: "rtkreducerreducer",
          target: "rtkreducerslice",
          type: "buttonedge",
          data: {
            popUpLocation: "middle",
            origin: true,
            payload: {
              description:
                "TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST ",
              code: "console.log();\nuseEffect(()=>{}, []);\nconst foo = (state: any) => {\n    return state.bar;\n}",
            },
            showButton: false,
          },
        },
      ],
      type: DevdocuTypes.DEVDOCU_SMALLGRAPH,
    },
    {
      header: 'Combining Multiple Reducers',
      descriptors: [{ type: SubheaderDescriptorTypes.CODE, content: "combineReducers()" }],
      type: DevdocuTypes.DEVDOCU_HEADER,
    },
    {
      content: [
        {
          text: "Multiple reducers can be combined into one reducer using <code>combineReducers()</code>.",
          type: DevdocuTypes.DEVDOCU_NORMALTEXT,
        },
      ],
      type: DevdocuTypes.DEVDOCU_DEFAULTPARAGRAPH,
    },
  ],
  type: DevdocuTypes.DEVDOCU_SECTIONTYPE,
};

const documentationDivider: DevdocuDocumentationDivider = {
  type: DevdocuTypes.DEVDOCU_DOCUMENTATIONDIVIDER,
};

export const rtkDocumentation: DevdocuDocumentationPage = {
  title: "Redux Toolkit",
  lastUpdated: "23.09.2023",
  tags: [
    { tag: "React", type: DevdocuTypes.DEVDOCU_TAG },
    { tag: "States", type: DevdocuTypes.DEVDOCU_TAG },
  ],
  content: [
    motivationSection,
    importantNotesSection,
    introductionSection,
    documentationDivider,
    storeSection,
    documentationDivider,
    reducerSection,
  ],
  type: DevdocuTypes.DEVDOCU_DOCUMENTATIONTYPE,
  project: rtkProjectName,
};
