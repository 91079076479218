import { Button, Skeleton } from "@nextui-org/react";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaCopy } from "react-icons/fa";
import { useSelector } from "react-redux";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDarkReasonable } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { ScreenWidth } from "../General/sizes";

type Props = { code: string };

export default function CodeSnippet({ code }: Props) {
  const screenWidth = useSelector((state: any) => {
    return state.rootReducer.appReducer.screenWidth.payload;
  });

  const resizingMainGraph = useSelector((state: any) => {
    return state.rootReducer.appReducer.resizingMainGraph;
  });

  return (
    <>
      {
        <div
          className={`relative overflow-x-scroll rounded-xl ${
            screenWidth === ScreenWidth.SM || screenWidth === ScreenWidth.MOBILE
              ? "w-[full]" //TODO fix this as well.
              : "w-[full]" //TODO this is a temporary solution to the sizing. The sizing occassionally goes off too big and overflows. THe syntax highlighter has some very wonky behavior.
          }`}
        >
          <SyntaxHighlighter
            wrapLines
            language="javascript"
            style={atomOneDarkReasonable}
            wrapLongLines
          >
            {code}
          </SyntaxHighlighter>
          <CopyToClipboard text={code}>
            <Button
              isIconOnly
              variant="bordered"
              size="sm"
              className="absolute top-0 right-0 m-3"
            >
              <FaCopy />
            </Button>
          </CopyToClipboard>
        </div>
      }
    </>
  );
}
