import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

// Slice for Redux Toolkit application demo

type RTKItem = {
  id: string;
  name: string;
}

interface RTKState {
  items: RTKItem[];
}

const initialState: RTKState = {
  items: [{name: 'Munich',id: '1973'}],
};

const rtkSlice = createSlice({
  name: 'rtk',
  initialState,
  reducers: {
    addItem: (state, action) => {
      state.items.push({name: action.payload, id: uuidv4()});
    }
  },
});

export const { addItem } = rtkSlice.actions;
export default rtkSlice.reducer;
