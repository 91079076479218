import React from "react";
import Subheader from "./Subheader";
import InfoParagraph from "./InfoParagraph";
import { FaInfoCircle } from "react-icons/fa";
import { Divider, Link, Skeleton, Spacer } from "@nextui-org/react";
import { useSelector } from "react-redux";
import { DevdocuParagraph, DevdocuTypes } from "../Mock/rtkdocumentation";
import { InformationSectionTypes } from "../General/types";

const mockContent: DevdocuParagraph = {
  content: [
    {
      type: DevdocuTypes.DEVDOCU_NORMALTEXT,
      text: "Mock text. Information. ",
    },
    {
      type: DevdocuTypes.DEVDOCU_POPOVER,
      text: "Foo",
      popoverText: "Bar",
    },
  ],
  title: "Mock Information",
  type: DevdocuTypes.DEVDOCU_INFORMATIONPARAGRAPH,
};

type Props = { content?: DevdocuParagraph; type?: InformationSectionTypes };

export default function InformationSection({
  content = mockContent,
  type = InformationSectionTypes.InformationSection,
}: Props) {
  const resizingMainGraph = useSelector((state: any) => {
    return state.rootReducer.appReducer.resizingMainGraph;
  });

  let backgroundColor, borderColor;
  switch (content.infotype) {
    case InformationSectionTypes.ExampleSection:
      backgroundColor = "#f913";
      borderColor = "#fa9";
      break;
    case InformationSectionTypes.InformationSection:
      backgroundColor = "#88f5";
      borderColor = "#88f";
      break;
    default:
      backgroundColor = "#88f5";
      borderColor = "#88f"; // Default to white if the preference is not recognized
      break;
  }

  return (
    <>
      <div
        className={`rounded-l-sm rounded-r-lg bg-opacity-20 px-6 py-3 flex flex-col my-3`}
        style={{
          backgroundColor: backgroundColor,
          borderRight: "solid 1px " + borderColor,
          borderTop: "solid 1px " + borderColor,
          borderBottom: "solid 1px " + borderColor,
          borderLeft: "solid 5px " + borderColor,
        }}
      >
        <div className="flex flex-row gap-3 items-center justify-start">
          <FaInfoCircle />
          <Subheader
            text={content.title ? content.title : "Undefined information title"}
          />
        </div>
        <p className="flex flex-row">
          <InfoParagraph content={content} />
        </p>
      </div>
    </>
  );
}
