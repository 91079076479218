
export enum ScreenWidth {
    MOBILE, SM, MD, LG, XL, XXL,
}

export type ComponentNode = {
  id: string;
};

export enum InformationSectionTypes {
  InformationSection,
  ExampleSection,
}

export type DataPath = {
  from: ComponentNode;
  to: ComponentNode;
};

export type DataPathWithOrderId = {
  path: DataPath;
  orderNumber: number;
};

export type DataRoute = {
  id: string;
  callerId: string;
  description?: string;
  dataRoute: DataPathWithOrderId[];
};

const defaultDataRoute: DataRoute = {
  id: "example-route",
  callerId: "example-button",
  description: "Default description",
  dataRoute: [
    { path: { from: { id: "A" }, to: { id: "B" } }, orderNumber: 0 },
    { path: { from: { id: "B" }, to: { id: "C" } }, orderNumber: 1 },
    { path: { from: { id: "C" }, to: { id: "A" } }, orderNumber: 2 },
  ],
};

export type DetailsSection = {
  id: string;
  title: string;
  content: string;
  code?: string;
};

export enum ScreenSize {
  Desktop = "DESKTOP",
  BigScreen = "BIG_SCREEN",
  Mobile = "MOBILE",
  Portrait = "PORTRAIT",
  Retina = "RETINA",
}

export type sectionIndex = {
  project: string;
  tags: string[];
  title: string;
  address: string;
  type: "section" | "snippet" | "template";
};
