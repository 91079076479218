/* ------------------------------ React Imports ----------------------------- */
import { useState } from "react";

/* ---------------------------- Packages Imports ---------------------------- */
import { Button, Card, Image, Select, SelectItem, } from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import { addItem, } from "../Redux/rtkSlice";

/* --------------------------- Components Imports --------------------------- */
import { animals } from "./animalsMockData";
import Monalisa from "../Resources/monalisaSquare.jpg";
import usePlayThroughRoutes from "../Graph/usePlayThroughRoute";

type Props = {};

export default function RtkApplication({}: Props) {
  /* ------------------------------ Custom Hooks ------------------------------ */
  const playThroughRoutes = usePlayThroughRoutes();
  /* ---------------------------------- Redux --------------------------------- */
  const rtk = useSelector((state: any) => state.rootReducer.rtkReducer);
  const dispatch = useDispatch();

  /* --------------------------------- States --------------------------------- */
  const [selectedItem, setSelectedItem] = useState("Default");

  /* ---------------------------- Snippet functions --------------------------- */
  const addItemHandler = (event: any) => {
    dispatch(addItem({ payload: selectedItem }));
    playThroughRoutes(event.target.id)
  };

  const handleSelectChange = (event: any) => {
    const newValue = event.target.value;
    setSelectedItem(newValue);
  };

  /* ------------------------------- Main Return ------------------------------ */
  return (
    <div className="w-[300px]">
      {/* Item list */}
      <div className="grid grid-cols-3 h-[200px] overflow-y-scroll">
        {rtk.items.map((i: any) => {
          return (
            <div className="p-1" key={i.key}>
              <Card>
                <Image src={Monalisa}></Image>
              </Card>
            </div>
          );
        })}
      </div>
      {/* Action area to add and select animal*/}
      <div className=" w-full flex items-center justify-center gap-3">
        <Button className="p-6" onClick={addItemHandler} id="rtk-main-add-button">
          Add Item
        </Button>
        <Select
          label="Select an animal"
          className="max-w-xs"
          onChange={handleSelectChange}
        >
          {animals.map((animal: any) => (
            <SelectItem key={animal.value} value={animal.value}>
              {animal.label}
            </SelectItem>
          ))}
        </Select>
      </div>
    </div>
  );
}
