import { DevdocuSnippet } from "../Sections/SnippetsSection";
import { DevdocuTypes } from "./rtkdocumentation";

export const mockRtkSnippets: DevdocuSnippet[] = [
    {
      id: '1',
      name: 'Create Slice',
      level: 'basic',
      description: 'Create a Redux Toolkit slice.',
      content: [
        {
          code: `
            import { createSlice } from '@reduxjs/toolkit';
  
            const mySlice = createSlice({
              name: 'myReducer',
              initialState: {},
              reducers: {
                // Add your reducer actions here
              },
            });
  
            export const { reducer } = mySlice;
            export const { actions } = mySlice;
          `,
          type: DevdocuTypes.DEVDOCU_CODESNIPPET,
        },
      ],
    },
    {
      id: '2',
      name: 'Create Async Thunk',
      level: 'intermediate',
      description: 'Create an async Redux Toolkit thunk.',
      content: [
        {
          code: `
            import { createAsyncThunk } from '@reduxjs/toolkit';
  
            export const fetchUserData = createAsyncThunk(
              'user/fetchUserData',
              async (userId, thunkAPI) => {
                // Add your async logic here
              }
            );
          `,
          type: DevdocuTypes.DEVDOCU_CODESNIPPET,
        },
      ],
    },
    {
      id: '3',
      name: 'Create Redux Store',
      level: 'basic',
      description: 'Create a Redux store with Redux Toolkit.',
      content: [
        {
          code: `
            import { configureStore } from '@reduxjs/toolkit';
            import rootReducer from './reducers';
  
            const store = configureStore({
              reducer: rootReducer,
            });
  
            export default store;
          `,
          type: DevdocuTypes.DEVDOCU_CODESNIPPET,
        },
      ],
    },
    {
      id: '4',
      name: 'Dispatch Action',
      level: 'basic',
      description: 'Dispatch a Redux action using Redux Toolkit.',
      content: [
        {
          code: `
            import { useDispatch } from 'react-redux';
            import { actions } from './mySlice';
  
            const dispatch = useDispatch();
  
            dispatch(actions.someAction(payload));
          `,
          type: DevdocuTypes.DEVDOCU_CODESNIPPET,
        },
      ],
    },
    {
      id: '5',
      name: 'Selectors',
      level: 'intermediate',
      description: 'Use selectors with Redux Toolkit.',
      content: [
        {
          code: `
            import { useSelector } from 'react-redux';
            import { selectData } from './mySlice';
  
            const data = useSelector(selectData);
          `,
          type: DevdocuTypes.DEVDOCU_CODESNIPPET,
        },
      ],
    },
    {
        id: '6',
        name: 'Create Entity Adapter',
        level: 'intermediate',
        description: 'Create an entity adapter with Redux Toolkit.',
        content: [
          {
            code: `
              import { createEntityAdapter } from '@reduxjs/toolkit';
    
              const userAdapter = createEntityAdapter({
                selectId: (user) => user.id,
                sortComparer: (a, b) => a.name.localeCompare(b.name),
              });
            `,
            type: DevdocuTypes.DEVDOCU_CODESNIPPET,
          },
        ],
      },
      {
        id: '7',
        name: 'Create Slice with Entity Adapter',
        level: 'intermediate',
        description: 'Create a Redux Toolkit slice with an entity adapter.',
        content: [
          {
            code: `
              import { createSlice } from '@reduxjs/toolkit';
              import { userAdapter } from './userAdapter';
    
              const userSlice = createSlice({
                name: 'users',
                initialState: userAdapter.getInitialState(),
                reducers: {
                  addUser: userAdapter.addOne,
                  updateUser: userAdapter.updateOne,
                  removeUser: userAdapter.removeOne,
                },
              });
            `,
            type: DevdocuTypes.DEVDOCU_CODESNIPPET,
          },
        ],
      },
      {
        id: '8',
        name: 'Thunk Error Handling',
        level: 'complex',
        description: 'Handle errors in Redux Toolkit async thunks.',
        content: [
          {
            code: `
              import { createAsyncThunk } from '@reduxjs/toolkit';
    
              export const fetchUserData = createAsyncThunk(
                'user/fetchUserData',
                async (userId, thunkAPI) => {
                  try {
                    // Add your async logic here
                  } catch (error) {
                    // Handle errors
                    thunkAPI.rejectWithValue(error.message);
                  }
                }
              );
            `,
            type: DevdocuTypes.DEVDOCU_CODESNIPPET,
          },
        ],
      },
      {
        id: '9',
        name: 'Middleware',
        level: 'complex',
        description: 'Use middleware with Redux Toolkit.',
        content: [
          {
            code: `
              import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
    
              const middleware = [
                // Add your custom middleware here
              ];
    
              const store = configureStore({
                reducer: rootReducer,
                middleware: [...getDefaultMiddleware(), ...middleware],
              });
            `,
            type: DevdocuTypes.DEVDOCU_CODESNIPPET,
          },
        ],
      },
      {
        id: '10',
        name: 'Immutability with `createSlice`',
        level: 'intermediate',
        description: 'Ensure immutability when using `createSlice` in Redux Toolkit.',
        content: [
          {
            code: `const userSlice = createSlice({\nname: 'user',initialState: {},reducers: {updateUser: (state, action) => {// Ensure immutability
state = { ...state, ...action.payload };
                  },
                },
              });
            `,
            type: DevdocuTypes.DEVDOCU_CODESNIPPET,
          },
        ],
      },
  ];