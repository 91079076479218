import { useState } from "react";
import {
  Button,
  Card,
  Divider,
  Image,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import Monalisa from "../Resources/monalisaSquare.jpg";
import reduxToolkitLogo from "../Resources/logoRedux.svg";
import { TypeAnimation } from "react-type-animation";
import bgShadow from "../Resources/bg-shadows.png";

import templateSvg from "../Resources/Group 1.svg";
import snippetSvg from "../Resources/Group 2.svg";
import graphSvg from "../Resources/Group 3.svg";
import Floaty from "../Components/Floaty";
import { DevDocuLogo } from "../Components/DevDocuLogo";

const description =
  "The problem of development documentation is the lack of good documentation. It's time to make documentation more visual, and human-readable.";

function MockaLisa() {
  return <Image src={Monalisa} width={200} height={200} />;
}

type Props = {};

export default function Home({}: Props) {
  const [discoverButtonHover, setDiscoverButtonHover] = useState(false);

  const previewClasses = "flex-col justify-center w-[70%] hidden md:flex items-center max-w-[40vh] min-w-[30vh]"

  return (
    <div
      id="main"
      className="flex flex-col min-h-[100vh]"
      style={{
        backgroundImage: `url(${bgShadow})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      }}
    >
      <div
        id="title-area"
        className={`flex flex-row px-[5%] sm:px-[5%] mb-10 h-[calc(100vh-70px)] items-center justify-center overflow-x-hidden`}
      >
        <div
          id="title-area-preview-snippets-and-templates"
          className={`${previewClasses}`}
        >
          <div className="relative -translate-y-[20%]">
            <Floaty
              child={
                <img
                  src={templateSvg}
                  className="scale-100 hover:scale-105 transition-all cursor-pointer"
                ></img>
              }
              initialY={3}
              initialRotate={1}
              delay={-1}
            />
            <div className="absolute top-1/2 translate-y-full">
              <Floaty
                child={
                  <img
                    src={snippetSvg}
                    className="scale-100 hover:scale-105 transition-all cursor-pointer"
                  ></img>
                }
                initialY={8}
                initialRotate={-0.5}
                delay={-3}
              />
            </div>
          </div>
        </div>
        <div
          id="title-area-text"
          className="flex flex-col justify-center text-center mx-8 max-w-md"
        >
          <div id="title-text" className="flex flex-col">
            <div
              className="bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-transparent bg-clip-text
              font-bold text-[2.7rem] sm:text-[4rem] sm:py-3"
            >
              Documentation
            </div>
            <TypeAnimation
              sequence={[
                2000, // Waits 1s
                "can be visual",
                2000, // Waits 2s
                "can be human-friendly",
                () => {
                  // console.log("Sequence completed");
                },
              ]}
              wrapper="div"
              cursor={true}
              repeat={Infinity}
              style={{}}
              className="text-2xl font-bold"
            />
          </div>
          <div
            id="subheader-text"
            className="text-xl animate-appearance-in font-semibold text-gray-400 pt-4"
          >
            Devdocu's pages{" "}
            <span
              className="font-bold text-gray-200 "
              style={{ textShadow: "0px 0px 30px white" }}
            >
              highlights
            </span>{" "}
            what you need to know about any framework, <i>streamlining</i> your
            <Popover placement="top">
              <PopoverTrigger className="cursor-pointer">
                <Link className="px-1 bg-white bg-opacity-50 rounded-lg font-semibold text-white text-xl">
                  development
                </Link>
              </PopoverTrigger>
              <PopoverContent className="dark text-foreground w-[20em]">
                <div className="px-1 py-2">
                  <div className="text-small">
                    Development - making something new
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </div>
          <div className="py-10 flex justify-center">
            <Link href="#discover">
              <Button
                size="lg"
                className="font-bold bg-black text-white flex flex-row gap-0 border border-neutral-800 border-[4px] hover:bg-white hover:border-white hover:text-black"
                style={{
                  boxShadow: "0px 0px 30px 0.1px white",
                }}
                onMouseEnter={() => {
                  setDiscoverButtonHover(true);
                }}
                onMouseLeave={() => {
                  setDiscoverButtonHover(false);
                }}
              >
                <DevDocuLogo
                  width="13px"
                  height="13px"
                  dark={discoverButtonHover}
                />
                iscover Docs
              </Button>
            </Link>
          </div>
        </div>
        <div
          id="title-area-preview-graph"
          className={`${previewClasses}`}
        >
          <Floaty
            child={
              <img
                src={graphSvg}
                className="scale-100 hover:scale-105 transition-all cursor-pointer"
              ></img>
            }
            initialY={-3}
            initialRotate={1}
            delay={-5}
          />
        </div>
      </div>
      <div id="content-area" className="px-[5vw] sm:px-[15vw] md:px-[22vw]">
        <div
          id="description-area"
          className="flex justify-center px-[5%] sm:px-[20%] mb-36 text-2xl font-bold text-center"
        >
          <div id="description">{description}</div>
        </div>
        <div
          id="graph-area"
          className=" mb-10 max-h-[30vh]
        flex flex-row"
        >
          <div id="graph-description" className="w-1/3">
            <div
              id="graph-description-title"
              className="text-2xl font-bold text-blue-600"
            >
              Graphs
            </div>
            <div id="graph-description-text">
              The framework you need, visualized.
            </div>
          </div>
          <div id="graph-preview" className=" w-2/3">
            <img src={graphSvg} className="object-contain w-full h-full" />
          </div>
        </div>
        <div
          id="snippet-area"
          className=" mb-10 max-h-[30vh]
        flex flex-row"
        >
          <div id="snippet-preview" className=" w-2/3">
            <img src={snippetSvg} className="object-contain w-full h-full" />
          </div>
          <div id="snippet-description" className="w-1/3">
            <div
              id="snippet-description-title"
              className="text-2xl font-bold text-blue-600"
            >
              Snippets
            </div>
            <div id="snippet-description-text">
              Small functions to help you avoid spending 3 hours figuring out
              why your simple sort doesn't work. D.R.Y!
            </div>
          </div>
        </div>
        <div
          id="templates-area"
          className=" mb-10 max-h-[30vh]
        flex flex-row"
        >
          <div id="templates-description" className="w-1/3">
            <div
              id="templates-description-title"
              className="text-2xl font-bold text-blue-600"
            >
              Templates
            </div>
            <div id="templates-description-text">
              Speed up development process by not needing to write and connect
              things yourself.
            </div>
          </div>

          <div id="templates-preview" className=" w-2/3">
            <img src={templateSvg} className="object-contain w-full h-full" />
          </div>
        </div>
        <div
          id="description-2-area"
          className="flex flex-col justify-center mx-auto mb-10"
        >
          <div
            id="description-2-title"
            className="flex flex-col grow-[2] justify-center items-center "
          >
            <div
              id="description-2-title-text"
              className="text-4xl font-bold text-center"
            >
              <span>Documentation</span> should be clear.
            </div>
            <div
              id="description-2-subheader-text"
              className="text-2xl text-center"
            >
              Why make everything so hard?
            </div>
          </div>
        </div>
        <section
          id="discover"
          className="flex flex-col justify-center mx-auto mb-10 items-center text-center"
        >
          <div id="start-cta" className="text-4xl font-bold mb-1">
            Start Here
          </div>
          <div id="start-cta-description" className="text-2xl mb-3">
            Check out our available documentation!
          </div>
          <div id="start-elements" className="flex flex-row grow gap-3">
            <div id="start-element-1">
              <Link href="/reduxtoolkit">
                <Card isPressable className="h-[150px] w-[300px]">
                  <div className="w-full h-full flex flex-row items-center justify-center py-10">
                    <div className="w-[50%] flex items-center justify-center">
                      <img src={reduxToolkitLogo} className="h-[70%] w-[70%]" />
                    </div>
                    <Divider orientation="vertical" />
                    <div className="w-[50%] text-2xl font-bold">
                      <div>
                        Redux
                        <br />
                        Toolkit
                      </div>
                      <div className=" text-sm font-normal">
                        React State Management Tool
                      </div>
                    </div>
                  </div>
                </Card>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
