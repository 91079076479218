/* ------------------------------ React Imports ----------------------------- */
import React, { useEffect, useState } from "react";

/* ------------------------ External Packages Imports ----------------------- */
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
  getSmoothStepPath,
} from "reactflow";
import {
  FaApple,
  FaBeer,
  FaChevronUp,
  FaCross,
  FaExclamation,
  FaRegWindowClose,
} from "react-icons/fa";
import { Button } from "@nextui-org/react";
import { useSelector } from "react-redux";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDarkReasonable } from "react-syntax-highlighter/dist/esm/styles/hljs";

/* ------------------------- Project Library Imports ------------------------ */
import "./button-edge.css";
import { getPathLength } from "../General/generalFunctions";
import { exampleCode } from "../Mock/exampleCode";
// import SyntaxHighlighter from "react-syntax-highlighter";

export default function ButtonEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}: EdgeProps) {
  /* ---------------------------------- Redux --------------------------------- */
  const activeRoute = useSelector(
    (state: any) => state.rootReducer.appReducer.activeDataRoute
  );
  const changesDetected = useSelector(
    (state: any) => state.rootReducer.appReducer.changesDetected
  );
  const activeSection = useSelector(
    (state: any) => state.rootReducer.appReducer.highlightedSection
  );

  /* --------------------------------- States --------------------------------- */
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const [edgeLength, setEdgeLength] = useState(getPathLength(edgePath));
  const [visibleDescription, setVisibleDescription] = useState(false);
  const [animatedOffset, setAnimatedOffset] = useState(0);

  /* ------------------------------- useEffects ------------------------------- */
  useEffect(() => {
    setEdgeLength(getPathLength(edgePath));
    setAnimatedOffset(0);
  }, [changesDetected]);

  useEffect(() => {
    if (activeRoute.payload === id) {
      setAnimatedOffset(animatedOffset - (edgeLength + dataBlockEdgeSize));
    }
  }, [activeRoute]);

  /* ----------------------------- Local Variables ---------------------------- */
  const dataBlockEdgeSize = 100;
  const buttonCustomStyle =
    activeSection.payload === id
      ? "ring ring-8 ring-[rgba(255,10,130,0.5)]"
      : "ring ring-8 ring-[rgba(255,255,255,0.5)]";

  const edgeStyle_animated = {
    strokeWidth: "10px",
    stroke: "white",
    // strokeDasharray: `${edgeLength} ${dataBlockEdgeSize}`, // Adjust the dash array length as needed
    // strokeDashoffset: animatedOffset,
    transition: "stroke-dashoffset 0.5s linear", // Adding a transition for smooth animation
    strokeLinecap: "round" as const,
  };

  const edgeStyle_animated_block = {
    strokeWidth: "7px",
    stroke: "red",
    strokeDasharray: `${dataBlockEdgeSize} ${edgeLength}`, // Adjust the dash array length as needed
    strokeDashoffset: animatedOffset + dataBlockEdgeSize,
    transition: "stroke-dashoffset 0.5s linear", // Adding a transition for smooth animation
    strokeLinecap: "round" as const,
  };

  const bottomPopUpClass =
    "-translate-x-1/2 left-1/2 translate-y-[10%] top-1/2";
  const topPopUpClass = "-translate-x-1/2 left-1/2 -translate-y-[110%] top-1/2";
  const leftPopUpClass =
    "-translate-x-[110%] left-1/2 -translate-y-1/2 top-1/2";
  const rightPopUpClass = "translate-x-[10%] left-1/2 -translate-y-1/2 top-1/2";
  const middlePopUpClass = "-translate-x-1/2 left-1/2 -translate-y-1/2 top-1/2";

  let PopUpClass = "translate-x-[30px] left-1/2 -translate-y-1/2 top-1/2";

  /* ------------------------ Pop Up Locations ------------------------ */
  if (data !== undefined && data.popUpLocation !== undefined) {
    switch (data.popUpLocation) {
      case "up":
        PopUpClass = topPopUpClass;
        break;
      case "down":
        PopUpClass = bottomPopUpClass;
        break;
      case "left":
        PopUpClass = leftPopUpClass;
        break;
      case "right":
        PopUpClass = rightPopUpClass;
        break;
      default:
        PopUpClass = middlePopUpClass;
        break;
    }
  }

  const onEdgeClick = (evt: any, id: any) => {
    evt.stopPropagation();
    if (data.type !== "icononly") {
      setVisibleDescription(!visibleDescription);
    }
  };

  return (
    <>
      <BaseEdge
        path={edgePath}
        style={edgeStyle_animated}
        markerEnd={markerEnd}
      />
      {!changesDetected && (
        <BaseEdge path={edgePath} style={edgeStyle_animated_block} />
      )}
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            pointerEvents: "all",
          }}
          className="nodrag nopan"
        >
          {data.showButton == true ? (
            <button
              className={`edgebutton text-[30px] ${buttonCustomStyle}`}
              onClick={(event) => onEdgeClick(event, id)}
            />
          ) : null}
          {/* Details of a button edge */}
          {visibleDescription && (
            <>
              <div
                className={`bg-white absolute text-black p-3 text-lg rounded-2xl ${PopUpClass} ${
                  visibleDescription ? "opacity-100" : "opacity-0"
                } transition-all w-[350px] max-h-[300px] overflow-y-scroll`}
                style={{ transitionDuration: "0.1s" }}
              >
                <div
                  style={{ userSelect: "text" }}
                  className=" cursor-text mb-12"
                >
                  "Lorem Ipsum Lorem Ipsum There's always a lot of drama to go
                  around" "Osiris just landed today, let's see if it's going to
                  be like the move Life. I really, really hope not."
                  <SyntaxHighlighter
                    language="javascript"
                    wrapLines
                    customStyle={{ borderRadius: "10px" }}
                    style={atomOneDarkReasonable}
                  >
                    dispatch()
                  </SyntaxHighlighter>
                </div>
                <Button
                  onClick={() => setVisibleDescription(false)}
                  className="absolute left-1/2 -translate-x-1/2 bottom-0 mb-3 text-black"
                  variant="light"
                  isIconOnly
                >
                  <FaChevronUp />
                </Button>
              </div>
            </>
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
