import { useDispatch, useSelector } from 'react-redux';
import { setActiveDataRoute } from "../Redux/appSlice";

const usePlayThroughRoutes = () => {
    const dispatch = useDispatch();
    const dataRoutes = useSelector((state: any) => state.rootReducer.appReducer.dataRoutes);

    const playThroughRoutes = (callerId: string) => {
        const dataRoute = dataRoutes.find((route: any) => route.callerId === callerId)
        if(!dataRoute) return;
        for (let i = 0; i <= dataRoute.dataRoute.length; i++) {
            if (i !== dataRoute.dataRoute.length) {
                setTimeout(() => {
                    dispatch(setActiveDataRoute({ payload: dataRoute.dataRoute[i].path.from.id + '_to_' + dataRoute.dataRoute[i].path.to.id }));
                }, 500 * i);
            } else {
                setTimeout(() => {
                    dispatch(setActiveDataRoute({ payload: "" }));
                }, 500 * i);
            }
        }
    }
    return playThroughRoutes;
}

export default usePlayThroughRoutes;
