import React, { useEffect, useState, useMemo, useRef } from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  MarkerType,
  Position,
  ReactFlowInstance,
} from "reactflow";
import CustomConnectionLine from "./CustomConnectionLine";
import { CustomNode } from "./CustomNode";
import ButtonEdge from "./ButtonEdge";
import {
  rtkStoreEdges,
  rtkStoreNodes,
} from "../Mock/rtkstoreGraphData";
import {
  getCollectiveNodesCenter,
  getGraphZoomLevel,
} from "../General/generalFunctions";
import { useSelector } from "react-redux";

type Props = {
  nodes: any;
  edges: any;
};

export default function ComponentGraph({ nodes, edges }: Props) {
  const nodeTypes = useMemo(() => ({ customNode: CustomNode }), []);
  const edgeTypes = useMemo(() => ({ buttonedge: ButtonEdge }), []);

  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
  const reactFlowWrapperRef = useRef<HTMLDivElement>(null);

  function onInitReactFlow(
    reactFlowInstance: ReactFlowInstance<any, any>
  ): void {
    setReactFlowInstance(reactFlowInstance);
  }

  const focusGraph = () => {
    if (reactFlowInstance !== undefined && reactFlowInstance !== null) {
      const nodes = reactFlowInstance?.getNodes();
      if (nodes !== null && nodes !== undefined) {
        const collectiveCenter = getCollectiveNodesCenter(nodes);
        const zoom =
          getGraphZoomLevel(nodes, reactFlowInstance, reactFlowWrapperRef) *
          0.8;
        reactFlowInstance.setCenter(collectiveCenter.x, collectiveCenter.y, {
          zoom,
          duration: 1000,
        });
      }
    }
  };

  // Disable connection
  const handleConnectStart = (event: any) => {
    event.preventDefault();
  };

  const changeCount = useSelector((state: any) => {
    return state.rootReducer.appReducer.changeCount;
  });

  useEffect(() => {
    focusGraph();
  }, [changeCount]);

  useEffect(() => {
    setTimeout(() => {
      focusGraph();
    }, 1000);
  }, []);

  // React flow component
  return (
    <div
      className="w-full h-[200px] pointer-events-none"
      style={{}}
      ref={reactFlowWrapperRef}
    >
      <svg className="w-0 h-0">
        <defs>
          <marker
            id="devdocuArrow"
            viewBox="0 0 10 10"
            refX="7"
            refY="5"
            markerUnits="strokeWidth"
            markerWidth="4"
            markerHeight="4"
            orient="auto"
          >
            <path d="M 0 0 L 10 5 L 0 10 z" fill="#fff" />
          </marker>
        </defs>
      </svg>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        connectionLineComponent={CustomConnectionLine}
        nodesDraggable={false}
        panOnDrag={false}
        zoomOnDoubleClick={false}
        zoomOnPinch={false}
        zoomOnScroll={false}
        autoPanOnNodeDrag={false}
        autoPanOnConnect={false}
        onConnectStart={handleConnectStart}
        onScroll={() => {}}
        preventScrolling
        fitView
        onInit={onInitReactFlow}
        fitViewOptions={{ padding: 0.4 }}
        minZoom={0}
      ></ReactFlow>
    </div>
  );
}
