import { Chip, Code } from "@nextui-org/react";
import React, { CSSProperties } from "react";

export enum SubheaderDescriptorTypes {
  CODE,
  COMPONENT,
}

export type SubheaderDescriptorType = {
  content: string;
  type: SubheaderDescriptorTypes;
};

type Props = {
  text: string;
  className?: string;
  style?: CSSProperties;
  descriptors?: SubheaderDescriptorType[];
};

export default function Subheader({
  text,
  className,
  style,
  descriptors,
}: Props) {
  const defaultClassName = "flex flex-row gap-3";
  const defaultStyles = {};

  const mergedClassName = className
    ? `${defaultClassName} ${className}`
    : defaultClassName;
  const mergedStyles = { ...defaultStyles, ...style };

  return (
    <div className={mergedClassName} style={mergedStyles}>
      <div className="text-2xl font-bold ">{text}</div>
      {descriptors !== undefined
        ? descriptors.map((descriptor) => {
            switch (descriptor.type) {
              case SubheaderDescriptorTypes.CODE:
                return <Code>{descriptor.content}</Code>;
              case SubheaderDescriptorTypes.COMPONENT:
                return <Chip>{descriptor.content}</Chip>;
            }
          })
        : null}
    </div>
  );
}
