// Goal: Create a type for search indexes so components, sections, etc. are easy to search through platforms such as Algolia.

const mockSectionIndexSection = {
    project: 'redux toolkit',
    tags: ['store','redux','toolkit','rtk', 'save', 'data','information', 'state management', 'management', 'configureStore'],
    title: 'store',
    address: '/reduxtoolkit#store',
    type: 'section',
}

const mockSectionIndexSection2 = {
    project: 'redux toolkit',
    tags: ['store','redux','toolkit','rtk', 'process', 'data','information', 'state management', 'management', 'update', 'configureStore', 'combineReducers'],
    title: 'reducer',
    address: '/reduxtoolkit#reducer',
    type: 'section',
}

const mockSectionIndexSection3 = {
    project: 'redux toolkit',
    tags: ['store','redux','toolkit','rtk', 'process', 'data','information', 'state management', 'management', 'update'],
    title: 'middleware',
    address: '/reduxtoolkit#middleware',
    type: 'section',
}

const mockSectionIndexSnippet1 = {
    project: 'redux toolkit',
    tags: ['store','redux','toolkit','rtk', 'process', 'data','information', 'state management', 'management', 'configureStore'],
    title: 'create store',
    address: '/reduxtoolkit#snippets?snippet=1',
    type: 'snippet',
}

const mockSectionIndexSnippet2 = {
    project: 'redux toolkit',
    tags: ['store','redux','toolkit','rtk', 'process', 'data','information', 'state management', 'management', 'configureStore', 'provider'],
    title: 'provide store',
    address: '/reduxtoolkit#snippets?snippet=2',
    type: 'snippet',
}

const mockSectionIndexSnippet3 = {
    project: 'redux toolkit',
    tags: ['store','redux','toolkit','rtk', 'process', 'data','information', 'state management', 'management', 'configureStore', 'useSelector'],
    title: 'get information from the store',
    address: '/reduxtoolkit#snippets?snippet=2',
    type: 'snippet',
}

const mockSectionIndexTemplate1 = {
    project: 'redux toolkit',
    tags: ['store','redux','toolkit','rtk', 'process', 'data','information', 'state management', 'management', 'configureStore', 'useSelector'],
    title: 'Basic',
    address: '/reduxtoolkit#templates?template=1',
    type: 'template',
}

const mockSectionIndexSection4 = {
    project: 'redux toolkit with React',
    tags: ['store', 'redux', 'toolkit', 'rtk', 'React', 'components', 'state management', 'configureStore', 'UI'],
    title: 'React Components',
    address: '/reduxtoolkit#react-components',
    type: 'section',
}

const mockSectionIndexSnippet4 = {
    project: 'redux toolkit with React',
    tags: ['store', 'redux', 'toolkit', 'rtk', 'React', 'components', 'state management', 'configureStore', 'UI'],
    title: 'Connect Redux to React Component',
    address: '/reduxtoolkit#snippets?snippet=4',
    type: 'snippet',
}

const mockSectionIndexSnippet5 = {
    project: 'redux toolkit with React',
    tags: ['store', 'redux', 'toolkit', 'rtk', 'React', 'components', 'state management', 'configureStore', 'UI', 'Redux Thunk'],
    title: 'Async Data Fetching with Redux Thunk',
    address: '/reduxtoolkit#snippets?snippet=5',
    type: 'snippet',
}

const mockSectionIndexTemplate2 = {
    project: 'redux toolkit with React',
    tags: ['store', 'redux', 'toolkit', 'rtk', 'React', 'components', 'state management', 'configureStore', 'UI'],
    title: 'Reusable UI Template',
    address: '/reduxtoolkit#templates?template=2',
    type: 'template',
}

const mockSectionIndexSection5 = {
    project: 'redux toolkit with React',
    tags: ['store', 'redux', 'toolkit', 'rtk', 'React', 'routing', 'state management', 'configureStore', 'UI'],
    title: 'React Router Integration',
    address: '/reduxtoolkit#react-router',
    type: 'section',
}

const mockSectionIndexSnippet6 = {
    project: 'redux toolkit with React',
    tags: ['store', 'redux', 'toolkit', 'rtk', 'React', 'routing', 'state management', 'configureStore', 'UI'],
    title: 'Navigation with React Router',
    address: '/reduxtoolkit#snippets?snippet=6',
    type: 'snippet',
}

const mockSectionIndexSection6 = {
    project: 'React Redux Toolkit Demo',
    tags: ['store', 'redux', 'toolkit', 'rtk', 'React', 'state management', 'configureStore', 'UI', 'Forms'],
    title: 'Handling Forms',
    address: '/reduxtoolkit#form-handling',
    type: 'section',
}

const mockSectionIndexSnippet7 = {
    project: 'React Redux Toolkit Demo',
    tags: ['store', 'redux', 'toolkit', 'rtk', 'React', 'state management', 'configureStore', 'UI', 'Forms'],
    title: 'Form State Management Example',
    address: '/reduxtoolkit#snippets?snippet=7',
    type: 'snippet',
}

const mockSectionIndexSnippet8 = {
    project: 'React Redux Toolkit Demo',
    tags: ['store', 'redux', 'toolkit', 'rtk', 'React', 'state management', 'configureStore', 'UI', 'Forms'],
    title: 'Submitting Forms with Redux Actions',
    address: '/reduxtoolkit#snippets?snippet=8',
    type: 'snippet',
}

const mockSectionIndexTemplate3 = {
    project: 'React Redux Toolkit Demo',
    tags: ['store', 'redux', 'toolkit', 'rtk', 'React', 'state management', 'configureStore', 'UI', 'Components'],
    title: 'Reusable UI Component Template',
    address: '/reduxtoolkit#templates?template=3',
    type: 'template',
}

const mockSectionIndexSection7 = {
    project: 'React Redux Toolkit Demo',
    tags: ['store', 'redux', 'toolkit', 'rtk', 'React', 'state management', 'configureStore', 'UI', 'Authentication'],
    title: 'User Authentication Flow',
    address: '/reduxtoolkit#user-authentication',
    type: 'section',
}

const mockSectionIndexSnippet9 = {
    project: 'React Redux Toolkit Demo',
    tags: ['store', 'redux', 'toolkit', 'rtk', 'React', 'state management', 'configureStore', 'UI', 'Authentication'],
    title: 'User Login and Logout Example',
    address: '/reduxtoolkit?snippet=9#snippets',
    type: 'snippet',
}

const mockSectionIndexSnippet10 = {
    project: 'React Redux Toolkit Demo',
    tags: ['store', 'redux', 'toolkit', 'rtk', 'React', 'state management', 'configureStore', 'UI', 'Authentication'],
    title: 'Access Control with Redux Toolkit',
    address: '/reduxtoolkit?snippet=10#snippets',
    type: 'snippet',
}

const mockReactSection1 = {
    project: 'React JS',
    tags: ['React', 'JavaScript', 'UI', 'Frontend', 'Components'],
    title: 'Getting Started with React',
    address: '/reactjs#getting-started',
    type: 'section',
}

const mockReactSnippet1 = {
    project: 'React JS',
    tags: ['React', 'JavaScript', 'UI', 'Frontend', 'Components'],
    title: 'Creating Your First React Component',
    address: '/reactjs#snippets?snippet=1',
    type: 'snippet',
}

const mockReactSnippet2 = {
    project: 'React JS',
    tags: ['React', 'JavaScript', 'UI', 'Frontend', 'Components', 'State Management'],
    title: 'State Management with React Hooks',
    address: '/reactjs#snippets?snippet=2',
    type: 'snippet',
}

const mockReactSection2 = {
    project: 'React JS',
    tags: ['React', 'JavaScript', 'UI', 'Frontend', 'Routing'],
    title: 'React Router Basics',
    address: '/reactjs#react-router',
    type: 'section',
}

const mockReactSnippet3 = {
    project: 'React JS',
    tags: ['React', 'JavaScript', 'UI', 'Frontend', 'Routing'],
    title: 'Creating Routes in React Router',
    address: '/reactjs#snippets?snippet=3',
    type: 'snippet',
}

// Continue adding more React JS sections and snippets.

const mockSpringBootSection1 = {
    project: 'Spring Boot',
    tags: ['Spring', 'Java', 'Backend', 'REST APIs'],
    title: 'Getting Started with Spring Boot',
    address: '/springboot#getting-started',
    type: 'section',
}

const mockSpringBootSnippet1 = {
    project: 'Spring Boot',
    tags: ['Spring', 'Java', 'Backend', 'REST APIs'],
    title: 'Creating Your First Spring Boot Application',
    address: '/springboot#snippets?snippet=1',
    type: 'snippet',
}

const mockSpringBootSnippet2 = {
    project: 'Spring Boot',
    tags: ['Spring', 'Java', 'Backend', 'Data Access', 'JPA'],
    title: 'Working with JPA in Spring Boot',
    address: '/springboot#snippets?snippet=2',
    type: 'snippet',
}

const mockSpringBootSection2 = {
    project: 'Spring Boot',
    tags: ['Spring', 'Java', 'Backend', 'Security'],
    title: 'Securing Your Spring Boot Application',
    address: '/springboot#security',
    type: 'section',
}

const mockSpringBootSnippet3 = {
    project: 'Spring Boot',
    tags: ['Spring', 'Java', 'Backend', 'Security'],
    title: 'Implementing Authentication with Spring Security',
    address: '/springboot?snippet=3#snippets',
    type: 'snippet',
}

// Continue adding more Spring Boot sections and snippets.

const mockAngularSection1 = {
    project: 'Angular',
    tags: ['Angular', 'TypeScript', 'Frontend', 'Components'],
    title: 'Getting Started with Angular',
    address: '/angular#getting-started',
    type: 'section',
}

const mockAngularSnippet1 = {
    project: 'Angular',
    tags: ['Angular', 'TypeScript', 'Frontend', 'Components'],
    title: 'Creating Your First Angular Component',
    address: '/angular#snippets?snippet=1',
    type: 'snippet',
}

const mockAngularSnippet2 = {
    project: 'Angular',
    tags: ['Angular', 'TypeScript', 'Frontend', 'Components', 'Forms'],
    title: 'Working with Angular Forms',
    address: '/angular#snippets?snippet=2',
    type: 'snippet',
}

const mockAngularSection2 = {
    project: 'Angular',
    tags: ['Angular', 'TypeScript', 'Frontend', 'Routing'],
    title: 'Angular Router Essentials',
    address: '/angular#routing',
    type: 'section',
}

const mockAngularSnippet3 = {
    project: 'Angular',
    tags: ['Angular', 'TypeScript', 'Frontend', 'Routing'],
    title: 'Creating Routes in Angular',
    address: '/angular#snippets?snippet=3',
    type: 'snippet',
}

export const mockIndex = [
    mockSectionIndexSection,
    mockSectionIndexSection2,
    mockSectionIndexSection3,
    mockSectionIndexSnippet1,
    mockSectionIndexSnippet2,
    mockSectionIndexSnippet3,
    mockSectionIndexTemplate1,
    mockSectionIndexTemplate2,
    mockSectionIndexSection4,
    mockSectionIndexSnippet4,
    mockSectionIndexSnippet5,
    mockSectionIndexTemplate2,
    mockSectionIndexSection5,
    mockSectionIndexSnippet6,
    mockSectionIndexSection6,
    mockSectionIndexSnippet7,
    mockSectionIndexSnippet8,
    mockSectionIndexTemplate3,
    mockSectionIndexSection7,
    mockSectionIndexSnippet9,
    mockSectionIndexSnippet10,
    mockReactSection1,
    mockReactSnippet1,
    mockReactSnippet2,
    mockReactSection2,
    mockReactSnippet3,
    mockSpringBootSection1,
    mockSpringBootSnippet1,
    mockSpringBootSnippet2,
    mockSpringBootSection2,
    mockSpringBootSnippet3,
    mockAngularSection1,
    mockAngularSnippet1,
    mockAngularSnippet2,
    mockAngularSection2,
    mockAngularSnippet3
];

