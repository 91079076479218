import React, { useEffect, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import {
  a11yDark,
  atomOneDarkReasonable,
} from "react-syntax-highlighter/dist/esm/styles/hljs";
import { exampleCode } from "../Mock/exampleCode";
import DataTree, { Directory, File } from "./DataTree";
import {
  findFile,
  getFileContentByName,
  getFileDescriptionByName,
} from "../General/generalFunctions";
import Subheader from "./Subheader";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button } from "@nextui-org/react";
import { FaCopy, FaGithub } from "react-icons/fa";

//TODO: height props is currently not behaving as expected
type Props = { height: string; files: Directory };

export default function FileExplorer({ height, files }: Props) {
  const [currentlyOpen, setCurrentlyOpen] = useState<string>("");
  const [highlightedLine, setHighlightedLine] = useState(-1);
  const [currentFile, setCurrentFile] = useState<File | null>(null);

  useEffect(()=>{
    if(currentlyOpen !== "") {
      setCurrentFile(findFile(currentlyOpen, files))
    }
    console.log(findFile(currentlyOpen, files))
    console.log("Good!")
  },[currentlyOpen])

  const getLineProps = (lineNumber: any) => {
    if (currentFile !== null && currentFile.highlighted !== undefined) {
      if (currentFile.highlighted.includes(lineNumber)) {
        return { style: { background: "rgba(255,255,0,0.3)" } };
      }
    }
    return {};
  };

  return (
    <div className="flex flex-col gap-3">
      <div
        className={`flex h-[600px] rounded-md bg-neutral-950 border-medium border-neutral-700 flex-col sm:flex-row`}
      >
        <div
          id="information-section-simulate-div-size"
          className="w-full h-full flex-1 pt-3"
        >
          <DataTree
            data={files}
            currentlyOpen={currentlyOpen}
            setCurrentlyOpen={setCurrentlyOpen}
          />
        </div>
        <div className="w-full h-full flex flex-col sm:flex-row overflow-y-scroll flex-[3] ">
          <div className={`sm:w-1/2 h-full bg-[#282c34] `}>
            {currentlyOpen === "" ? (
              <div className="flex flex-col items-center justify-center h-full ">
                <Subheader text={"Select a file to view its contents"} />
              </div>
            ) : (
              <div className="relative ">
                <SyntaxHighlighter
                  language="javascript"
                  style={atomOneDarkReasonable}
                  wrapLines
                  showLineNumbers
                  lineProps={(lineNumber) => {
                    const customStyles = getLineProps(lineNumber).style || {};
                    return {
                      style: {
                        ...customStyles,
                        // wordBreak: "break-all",
                        // whiteSpace: "pre-wrap",
                      },
                    };
                  }}
                >
                  {getFileContentByName(currentlyOpen, files) as string}
                </SyntaxHighlighter>
                <CopyToClipboard
                  text={getFileContentByName(currentlyOpen, files) as string}
                  onCopy={() => {
                    alert("copied");
                  }}
                >
                  <Button
                    isIconOnly
                    variant="bordered"
                    size="sm"
                    className="absolute top-0 right-0 m-3"
                  >
                    <FaCopy />
                  </Button>
                </CopyToClipboard>
              </div>
            )}
          </div>
          <div className={`sm:w-1/2 h-full p-8`}>
            {getFileDescriptionByName(currentlyOpen, files) as string}
          </div>
        </div>
      </div>
      <Button className="mx-auto" color="primary">
        View on GitHub
        <FaGithub />
      </Button>
    </div>
  );
}
