export type NavigationSection = { sectionNames: string[], sectionGroup: string }

export const mockNavigationSection: NavigationSection[] = [
    {
        sectionGroup: 'guide',
        sectionNames: ['motivation','introduction']
    },
    {
        sectionGroup: 'documentation',
        sectionNames: ['doc1','doc2']
    },
]