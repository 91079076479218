/* ---------------------------- Packages Imports ---------------------------- */
import { ResizableBox } from "react-resizable";
import "../Components/resizable.css";
/* ------------------------------ React Imports ----------------------------- */

/* ---------------------------- Component Imports --------------------------- */
import Graph from "../Graph/Graph";
import ComponentGraphSection from "../Sections/ComponentGraphSection";
import TemplatesSection from "../Sections/TemplatesSection";
import SideNavigation from "../Navigation/SideNavigation";
import Title from "../Components/Title";
import Paragraph from "../Components/Paragraph";
import {
  LOREM_IPSUM,
  NAVBAR_HEIGHT_NUM,
  NAVBAR_HEIGHT_PX,
} from "../General/constants";
import { rtkDataRoutes, rtkMainEdges, rtkMainNodes } from "../Mock/rtkmainGraphData";
import ComponentDetails from "../Sections/ComponentDetailsSection";
import { rtkStoreDetails } from "../Mock/rtkStoreDetails";
import { rtkStoreEdges, rtkStoreNodes } from "../Mock/rtkstoreGraphData";
import "../App.css";
import { useEffect, useRef, useState } from "react";
import { Button, Chip, Divider, Link, Spacer } from "@nextui-org/react";
import LearnSlide from "../Components/LearnSlide";
import BeginnerReminder from "../Components/BeginnerReminder";
import Subheader from "../Components/Subheader";
import { rtkReducerDetails } from "../Mock/rtkReducerDetails";
import { rtkDispatchDetails } from "../Mock/rtkDispatchDetails";
import InfoParagraph from "../Components/InfoParagraph";
import FileExplorer from "../Components/FileExplorer";
import { mockDataDataTree } from "../Mock/mockDataDataTree";
import SnippetsSection from "../Sections/SnippetsSection";

import LogoRedux from "../Resources/logoRedux.svg";
import Draggable from "react-draggable"; // The default
import { FaStickyNote } from "react-icons/fa";
import { Rnd } from "react-rnd";
import PiPGraph from "../Graph/PiPGraph";

import bgShadow from "../Resources/bg-shadows.png";
import { DevdocuDocumentationPage, rtkDocumentation } from "../Mock/rtkdocumentation";
import DevdocuSection from "../Sections/DevdocuSection";
import { pageToNavigationSections } from "../General/generalFunctions";
import { useDispatch, useSelector } from "react-redux";
import { ScreenSize } from "../General/types";
import { incrementChangeCount } from "../Redux/appSlice";
/* ---------------------------------- Main ---------------------------------- */

export interface IPageProps {
  page?: DevdocuDocumentationPage;
}

export function Page({ page = rtkDocumentation }: IPageProps) {
  const pRef = useRef<HTMLParagraphElement>(null);
  const [bgColor, setBgColor] = useState("bg-white");
  const [horizontalPadding, setHorizontalPadding] = useState("");
  const [isResizing, setResizing] = useState(false);

  const [queriedSnippet, setQueriedSnippet] = useState(0);

  const dispatch = useDispatch()

  const updateStyles = () => {
    if (pRef.current) {
      const width = pRef.current.offsetWidth;

      // Adjust this logic based on your desired background colors for the widths
      if (width <= 650) {
        setBgColor("bg-red-500");
        setHorizontalPadding("px-[5%]");
      } else if (width > 650 && width <= 900) {
        setBgColor("bg-yellow-500s");
        setHorizontalPadding("px-[7.5%]");
      } else if (width > 900 && width <= 1100) {
        setBgColor("bg-green-500");
        setHorizontalPadding("px-[11%]");
      } else {
        setBgColor("bg-blue-500");
        setHorizontalPadding("px-[15%]");
      }
      dispatch(incrementChangeCount())
    }
  };

  useEffect(() => {
    if (pRef.current) {
      // Use ResizeObserver to watch for size changes
      const resizeObserver = new ResizeObserver(updateStyles);

      resizeObserver.observe(pRef.current);

      // Initial background color update
      updateStyles();

      // Cleanup
      return () => resizeObserver.disconnect();
    }
  }, [isResizing]);

  //TODO check safety
  useEffect(() => {
    // Function to parse query parameters from the current URL
    function parseQueryParameters(url: string) {
      const parts = url.split("#"); // Split the URL at "#" to separate the query and fragment
      const queryString =
        parts.length > 1 ? parts[0].split("?")[1] : url.split("?")[1]; // Extract the query string

      const params: any = {};

      if (queryString) {
        const paramPairs = queryString.split("&");

        paramPairs.forEach((pair: string) => {
          const [key, value] = pair.split("=");
          if (key && value) {
            params[key] = decodeURIComponent(value); // Decode URL-encoded values
          }
        });
      }

      return params;
    }

    // Get the current URL
    const currentURL = window.location.href;
    // Parse query parameters and set the state
    const parsedParams = parseQueryParameters(currentURL);

    if (parsedParams.snippet) {
      setQueriedSnippet(parsedParams.snippet);
    }

    // Get the hash (fragment identifier)
    const currentHash = window.location.hash;
    // Scroll to the element identified by the hash
    if (currentHash) {
      const element = document.querySelector(currentHash);
      if (element) {
        setTimeout(() => element.scrollIntoView(), 300);
      }
    }
  }, []); // The empty dependency array ensures this effect runs once on component mount

  const screenSize = useSelector((state: any) => {
    return state.rootReducer.appReducer.screenSize;
  });
  /* ---------------------------------- Main ---------------------------------- */
  return (
    <>
      <>
        <div id="page" className="flex flex-row">
          <div
            id="bg"
            className="fixed top-0 h-screen w-screen"
            style={{
              backgroundImage: `url(${bgShadow})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
            }}
          ></div>
          <SideNavigation
            navigationItems={pageToNavigationSections(page)}
            projectLogo={LogoRedux}
          />
          <div id="main-content" className="flex flex-col relative pt-3">
            <div className="fixed z-[100]">
              <PiPGraph
                nodes={rtkMainNodes}
                edges={rtkMainEdges}
                dataRoutes={rtkDataRoutes}
              />
            </div>
            <section
              id="visit-introduction"
              className="navbar-scroll-offset flex flex-col sm:flex-row items-center justify-center gap-3"
            >
              <div className="text-center ">
                New to Redux Toolkit? Check the onboarding
              </div>
              <Link href="/reduxtoolkit-introduction">
                <Button color="primary" variant="bordered">
                  Start Your Path
                </Button>
              </Link>
            </section>
            <section id="landing-text" className="my-[0vh]">
              <div className="flex flex-col items-center justify-center p-[5%]">
                <img src={LogoRedux} alt="Redux Logo" className=" h-[7rem]" />
                <div className=" text-4xl font-bold">{page.title}</div>
                <div className="text-sm text-opacity-70">
                  Last updated: {page.lastUpdated}
                </div>
                <div className="flex flex-row gap-2 flex-wrap">
                  {page.tags.map((tag) => {
                    return <Chip variant="bordered">{tag.tag}</Chip>;
                  })}
                </div>
              </div>
            </section>
            <div
              id="documentation"
              className={`flex md:flex-row flex-col-reverse`}
            >
              <div
                id="text-documentation"
                ref={pRef}
                className={` flex flex-col py-5 ${horizontalPadding}`}
                style={{
                  transition: "all 0.5s",
                }}
              >
                {page.content.map((section, index) => {
                  const isLastSection = index === page.content.length - 1;
                  return (
                    <DevdocuSection
                      data={section}
                      last={isLastSection}
                      key={index} // Make sure to add a unique key prop when mapping over elements
                    />
                  );
                })}
                <Spacer y={32} />
              </div>
              <div className="sticky top-[3rem] z-[20]">
                <div
                  id="graph-documentation"
                  className="navbar-scroll-offset sticky top-[0]"
                >
                  <Graph
                    nodes={rtkMainNodes}
                    edges={rtkMainEdges}
                    dataRoutes={rtkDataRoutes}
                    isResizing={isResizing}
                    setResizing={setResizing}
                  />
                </div>
              </div>
            </div>
            <section id="structure" className={`navbar-scroll-offset`}>
              <div className="p-10 md:px-[3%] sm:px-[3%]">
                <Title title="Project Structure" />
                <FileExplorer height="700" files={mockDataDataTree} />
              </div>
            </section>
            <section id="snippets" className="navbar-scroll-offset">
              <div className="p-10 md:px-[3%] sm:px-[3%]">
                <Title title="Snippets" />
                <SnippetsSection queriedSnippet={queriedSnippet} />
              </div>
            </section>
            <section id="templates" className="navbar-scroll-offset">
              <div className="p-10 md:px-[3%] sm:px-[3%]">
                <Title title="Templates" />
                <TemplatesSection />
              </div>
            </section>
            <section id="comments" className=""></section>
          </div>
        </div>
        <BeginnerReminder />
      </>
      )
    </>
  );
}
