import React from 'react'
import HomeGraph from '../Graph/HomeGraph'
import { rtkMainEdges, rtkMainNodes } from '../Mock/rtkmainGraphData'

type Props = {text?: string}

export default function LearnSlide({text}: Props) {


  return (
    <div className={`h-[calc(70vh-70px)]`}>
        <div className='flex flex-row items-center justify-center h-full'>
            <div className=' w-[30vw] text-4xl'>
                {text}
            </div>
            <div className='w-[30vw] h-[40vh] '>
                <HomeGraph nodes={rtkMainNodes} edges={rtkMainEdges} dataRoutes={undefined}/>
            </div>
        </div>          
    </div>
  )
}

LearnSlide.defaultProps = {
    text: 'Default Text Here, This is where the reader can see a very quick introduction text.', // Set your default text value here
};