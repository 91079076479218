import React from 'react'
import LearnSlide from '../Components/LearnSlide'
import { Button, Link } from '@nextui-org/react'

type Props = {}

export default function IntroPage({}: Props) {
  return (
    <div className='flex flex-col items-center justify-center'>
        <LearnSlide/>
        <LearnSlide/>
        <LearnSlide/>
        <Link href='/reduxtoolkit'>
        <Button size='lg' color='primary' className='hover:scale-105'>
            Continue to Documentation
        </Button>
        </Link>
    </div>
  )
}