import { combineReducers } from '@reduxjs/toolkit';
import rtkReducer from './rtkSlice';
import appReducer from './appSlice';

// Central area for all reducers 

const rootReducer = combineReducers({
  rtkReducer,
  appReducer,
});

export default rootReducer;
