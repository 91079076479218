/* ------------------------------ React Imports ----------------------------- */
import { useCallback, useEffect, useRef, useState } from "react";

/* ---------------------------- Packages Imports ---------------------------- */
import { Button, Card, CardBody, CardHeader, Link } from "@nextui-org/react";
import {
  FaAccusoft,
  FaAlgolia,
  FaCalendar,
  FaChevronUp,
  FaExpandArrowsAlt,
  FaInfo,
  FaRegHandPointer,
  FaSave,
  FaWindowMinimize,
} from "react-icons/fa";
import { Handle, Position } from "reactflow";
import { useSelector, useDispatch } from "react-redux";
// import SyntaxHighlighter from "react-syntax-highlighter";

/* --------------------------- Components Imports --------------------------- */
import RtkApplication from "../Mock/RtkApplication"; //TODO refactor
import { exampleCode } from "../Mock/exampleCode";
import "./custom-node.css";
import SyntaxHighlighter from "react-syntax-highlighter";
import { spacePascalCase, toPascalCase } from "../General/generalFunctions";
import {
  a11yDark,
  atomOneDarkReasonable,
} from "react-syntax-highlighter/dist/esm/styles/hljs";

import Logo from "../../../Resources/logo-devdoc-black.svg";

export function CustomNode({ data }: any) {
  /* ---------------------------------- Redux --------------------------------- */
  const activeDataRoute = useSelector(
    (state: any) => state.rootReducer.appReducer.activeDataRoute.payload
  );
  const higlightedSection = useSelector(
    (state: any) => state.rootReducer.appReducer.highlightedSection.payload
  );

  /* --------------------------------- States --------------------------------- */
  const [nodeOpen, setNodeOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  /* ---------------------------- Helper functions ---------------------------- */
  //TODO Check why typescript is throwing an error here
  useEffect(() => {
    if (contentRef.current) {
      // @ts-ignore
      setContentHeight(nodeOpen ? contentRef.current.scrollHeight : 0);
    }
  }, [nodeOpen]);

  /* -------------------------------- Mock Data ------------------------------- */
  const defaultDescription =
    "Node description 101. This is where you explain roughly and generally what this certain component does.";

  /* ------------------------------- Custom CSS ------------------------------- */
  const highlightedNodeClass =
    activeDataRoute !== undefined && activeDataRoute !== ""
      ? activeDataRoute.split("_")[0] === data.value
        ? "ring-inset ring-[10px] ring-green-600"
        : ""
      : higlightedSection === data.value && higlightedSection !== ""
      ? "ring-inset ring-[10px] ring-blue-600"
      : "";
  const iconSize = nodeOpen ? 30 : 70;
  const paddingSize = nodeOpen ? '0px' : '20px';

  const handleOpen = () => {
    if (data.type !== "icononly" && data.isClickable === true) {
      setNodeOpen(!nodeOpen);
    }
  };
  /* ------------------------------- Main Return ------------------------------ */
  return (
    /* ---------------------------------- Node ---------------------------------- */
    <div
      className={` bg-white p-4 border transition-all rounded-3xl ${highlightedNodeClass} text-lg cursor-default `}
    >
      {data.isMovable == true ? (
        <div className="absolute bg-[rgb(0,0,0,0.5)] w-[45px] h-[45px] rounded-full -top-[0] flex items-center justify-center left-0 -translate-x-1/2 -translate-y-1/2 drag-handle cursor-move transition-all">
          <FaExpandArrowsAlt />
        </div>
      ) : null}
      {/* Node header or title */}
      <div className="left-0 flex flex-row gap-3 align-middle drag-handle text-black">
        <div className={`font-bold ${!(data.emoji || data.type === 'app') && " w-24 h-24 text-center items-center flex"}`}>
          {spacePascalCase(toPascalCase(data.value))}
        </div>
      </div>

      {/* Node content */}
      <div className="flex flex-col text-black">
        {data.type === "app" && data.payload !== undefined ? ( //Case app
          <data.payload />
        ) : (
          //Case normal text
          <div style={{ overflow: "hidden" }} className="relative">
            <div className={`flex flex-row`} id="icon">
              {
                data.emoji && 
                <div
                onClick={handleOpen}
                className={` ${
                  data.isClickable ? "cursor-pointer" : "cursor-default"
                } transition-all text-center justify-center items-center flex`}
                style={{ fontSize: iconSize, height: ((iconSize)), paddingTop: (paddingSize), paddingBottom: paddingSize }}
              >
                {data.emoji}
              </div>}
              <div
                className={`${nodeOpen ? "" : "hidden"} absolute right-0 top-0`}
              >
                <Link href="404">Docs</Link>
              </div>
            </div>
            <div
              style={{
                maxHeight: nodeOpen ? `${contentHeight}px` : "0",
                maxWidth: nodeOpen ? `350px` : "0",
                opacity: nodeOpen ? 1 : 0,
                transition:
                  "opacity 0.3s ease, max-height 0.3s ease, max-width 0.05s ease-in-out",
                overflow: "hidden",
              }}
              ref={contentRef}
            >
              {nodeOpen && (
                <div className="p-2">
                  <div id="node-description">{data.payload.description}</div>
                  <div id="node-code">
                    <SyntaxHighlighter
                      language="javascript"
                      wrapLines
                      style={atomOneDarkReasonable}
                      customStyle={{ borderRadius: "10px" }}
                    >
                      configureStore()
                    </SyntaxHighlighter>
                  </div>
                  <div className="flex flex-row w-full justify-center pt-6">
                    <Button
                      isIconOnly
                      variant="light"
                      className="text-black"
                      onClick={handleOpen}
                    >
                      <FaChevronUp />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Handles with position based on source and target position passed parameters */}
      <Handle
        type="source"
        position={data.sourcePosition ? data.sourcePosition : Position.Right}
        id="handle"
        className="handle"
        isConnectableStart={false}
      />
      <Handle
        type="target"
        position={data.targetPosition ? data.targetPosition : Position.Left}
        id="handle"
        className="handle"
        isConnectableStart={false}
      />
    </div>
  );
}
