import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "./data-tree.css";
import { mockDataDataTree } from "../Mock/mockDataDataTree";
import "../App.css";
import { FaFile, FaFolder, FaGithub } from "react-icons/fa";
import { Button } from "@nextui-org/react";

export type File = {
  type: "file";
  name: string;
  content: string;
  description: string;
  highlighted?: number[];
};

export type Directory = {
  type: "directory";
  name: string;
  children: Array<File | Directory>;
};

type Node = File | Directory;

type Props = {
  data?: Directory;
  currentlyOpen: string;
  setCurrentlyOpen: any;
};

export default function DataTree({
  data = mockDataDataTree,
  currentlyOpen = "",
  setCurrentlyOpen,
}: Props) {
  return (
    <div className="h-full overflow-y-scroll">
      <NodeComponent
        node={data}
        depth={0}
        setCurrentlyOpen={setCurrentlyOpen}
        currentlyOpen={currentlyOpen}
      />
    </div>
  );
}

const depthPaddingAmount = 20;
const depthPaddingMinimum = 10;

function NodeComponent({
  node,
  depth,
  setCurrentlyOpen,
  currentlyOpen,
}: {
  node: Node;
  depth: number;
  setCurrentlyOpen: any;
  currentlyOpen: any;
}) {
  const paddingLeft = depth * depthPaddingAmount + depthPaddingMinimum; // Increase padding based on depth

  if (node.type === "file") {
    return (
      <div
        style={{ paddingLeft }}
        className={`
    cursor-pointer bg-neutral-800 hover:bg-neutral-600 transition-all
    p-1 border-b-1 border-neutral-500 flex flex-row gap-3 items-center
    ${currentlyOpen === node.name ? "active" : ""}`}
        onClick={() => {
          setCurrentlyOpen(node.name);
        }}
      >
        <FaFile />
        {node.name}
      </div>
    );
  } else {
    return (
      <div className="relative">
        <Accordion allowMultipleExpanded allowZeroExpanded>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton
                style={{
                  display: "flex",
                  gap: "0.75rem",
                  alignItems: "center",
                  flexDirection: "row",
                  paddingLeft,
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderBottom: "0.1rem solid rgb(115,115,115)",
                }}
              >
                <FaFolder />
                {node.name}
              </AccordionItemButton>
            </AccordionItemHeading>
            {node.children.map((child, index) => (
              <AccordionItemPanel key={index} className="">
                <NodeComponent
                  node={child}
                  depth={depth + 1}
                  setCurrentlyOpen={setCurrentlyOpen}
                  currentlyOpen={currentlyOpen}
                />
              </AccordionItemPanel>
            ))}
          </AccordionItem>
        </Accordion>
      </div>
    );
  }
}
