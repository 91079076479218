import React, { useEffect, useState } from "react";

import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Pagination,
  Configure,
} from "react-instantsearch";
import { searchClient } from "../secrets/secrets";
import { HotkeyListener } from "../Components/HotkeyListener";
import { Checkbox, Chip, Link, Spinner, input } from "@nextui-org/react";
import {
  Fa500Px,
  FaAccessibleIcon,
  FaAddressBook,
  FaHashtag,
  FaPuzzlePiece,
  FaSearch,
  FaTimes,
} from "react-icons/fa";

import "./DevdocuSearch.css";

import AlgoliaLogo from "../Resources/Algolia-logo-blue.png";
import { DevDocuLogo } from "../Components/DevDocuLogo";

const mockHitDescription = "Mock description";

function Hit({ hit }: any, searchItems: any, onModalClose?: any) {
  console.log(hit.type);
  console.log(searchItems);

  return (
    <>
      {searchItems.includes(hit.type) && (
        <div
          className={`text-white w-full transition-all bg-neutral-800 hover:bg-neutral-700`}
        >
          <div
            className="p-3 border-b-1 border-neutral-700 flex flex-row
    transition-all cursor-pointer
    "
            onClick={() => {
              window.location.href = hit.address;
              onModalClose();
            }}
          >
            <div className="h-full flex my-auto mr-[1rem] text-3xl">
              {(() => {
                switch (hit.type) {
                  case "snippet":
                    return <FaPuzzlePiece />;
                  case "section":
                    return <FaHashtag />;
                  case "template":
                    return <FaAddressBook />;
                  default:
                    return <DevDocuLogo />;
                }
              })()}
            </div>
            <div className="flex flex-col">
              <div className="font-bold">{hit.title}</div>
              <div>{hit.project}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

type Props = { onModalClose?: any; searchItems?: string[] };

export default function DevdocuSearch({ onModalClose, searchItems }: Props) {
  const [searching, setSearching] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);

  console.log(searchItems);

  useEffect(() => {
    if (isInitialRender) {
      // Use a setTimeout to ensure that the animation starts after the initial render
      setTimeout(() => {
        setIsInitialRender(false);
      }, 0);
    }
  }, [isInitialRender]);

  const initialRenderAnimationClass = `${
    isInitialRender ? "max-h-[0vh]" : "max-h-[40vh]"
  }`;

  let timerId: string | number | NodeJS.Timeout | undefined = undefined;

  //Debounce searches so searching doesn't happen too frequently.
  function queryHook(query: any, search: any) {
    setSearching(true);

    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      search(query);
      setSearching(false);
    }, 500);
  }

  const [filterDocumentation, setFilterDocumentation] = useState(
    (searchItems as string[]).includes("section")
  );
  const [filterSnippets, setFilterSnippets] = useState(
    (searchItems as string[]).includes("snippet")
  );
  const [filterTemplates, setFilterTemplates] = useState(
    (searchItems as string[]).includes("template")
  );

  return (
    <>
      <InstantSearch searchClient={searchClient} indexName="test_devdocu">
        <SearchBox
          queryHook={queryHook}
          placeholder={"Search Devdocu"}
          submitIconComponent={() => (
            <div className="absolute right-[4rem] top-1 p-3 text-neutral-500 hover:text-neutral-50 transition-all">
              {searching ? (
                // <FaSearch />
                <Spinner size="sm" />
              ) : (
                <FaSearch />
              )}
            </div>
          )}
          resetIconComponent={() => (
            <div className="absolute right-[6rem] top-1 p-3 text-red-300 hover:text-red-500 transition-all">
              <FaTimes />
            </div>
          )}
          // classNames={object}
          // ...props={ComponentProps<'div'>}
          classNames={{ input: " p-3 m-0 w-full" }}
          autoFocus
        />
        <div
          id="search-modifiers"
          className=" w-full flex flex-row justify-start items-center gap-3 py-2"
        >
          {/* <Checkbox
            defaultChecked={(searchItems as string[]).includes("snippet")}
            checked={filterSnippets}
            defaultSelected={filterSnippets}
            onValueChange={(value)=>{
            }}
          >
            {" "}
            Snippets{" "}
          </Checkbox>
          <Checkbox
            defaultChecked={(searchItems as string[]).includes("template")}
            checked={filterTemplates}
            defaultSelected={filterTemplates}
          >
            {" "}
            Templates{" "}
          </Checkbox>
          <Checkbox
            defaultChecked={(searchItems as string[]).includes("section")}
            checked={filterDocumentation}
            defaultSelected={filterDocumentation}
          >
            {" "}
            Documentation{" "}
          </Checkbox> */}
          {searchItems?.map((i) => {
            return(
              <Chip className="ml-1" radius="sm" variant="faded">
                {i}
              </Chip>
            )
          })}
        </div>
        <div
          className={`overflow-y-scroll transition-all ${
            isInitialRender ? "h-[0vh]" : "h-[40vh]"
          }`}
        >
          <Hits
            hitComponent={(hit) => {
              return Hit(hit, searchItems, onModalClose);
            }}
          />
        </div>
      </InstantSearch>
      <div className="p-2 w-full right-0 flex flex-row justify-end h-[30px] items-center text-small text-neutral-600 gap-2">
        {"Search powered by "}
        <img src={AlgoliaLogo} className="h-[100%]" />
      </div>
    </>
  );
}
