import { Button, Link } from "@nextui-org/react";
import React from "react";

type Props = {};

export default function NoMatch({}: Props) {
  return (
    <div className="bg-neutral-900 h-screen flex justify-center items-center">
      <div className="bg-neutral-800 w-[70%] max-w-[500px] p-[3%] rounded-lg shadow-lg flex flex-col justify-center items-center gap-3">
        <div className="w-fulltext-center">
          <span
            className="bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-transparent bg-clip-text
              font-bold text-[4rem] text-center"
          >
            404.
          </span>
          <span className="font-bold text-2xl">
            {" "}
            How did you get here? Here's a smiley face: 😄
          </span>
          <span className="font-normal text-md text-neutral-400">
            <br/>
            If you're wondering why you're seeing this page, the page you tried to navigate to
            unfortunately doesn't exist on <Link href="/">Devdocu.com</Link>. We do though, have other pages you can take a look at!
          </span>
        </div>
        <div className="flex flex-row flex-wrap max-w-[450px] gap-3">
        <Link href="/">
          <Button color="primary" className="p-6 text-xl font-semibold">
            Home 🏠
          </Button>
        </Link>
        <Link href="/about">
          <Button color="primary" className="p-6 text-xl font-semibold">
            About 💻
          </Button>
        </Link>
        </div>
      </div>
    </div>
  );
}
