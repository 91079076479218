import React, { useEffect, useState } from "react";
import { ScreenSize, ScreenWidth } from "../General/types";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionItem,
  Button,
  Link,
  Switch,
  Tooltip,
} from "@nextui-org/react";
import {
  NavigationSection,
  mockNavigationSection,
} from "../Mock/rtkDocumentationNavigation";
import LogoRedux from "../Resources/logoRedux.svg";
import {
  FaArrowLeft,
  FaBook,
  FaChevronLeft,
  FaChevronRight,
  FaHamburger,
  FaHeadSideCough,
  FaHeart,
  FaHeartBroken,
  FaHome,
  FaNetworkWired,
  FaPuzzlePiece,
  FaRegHeart,
  FaRegObjectGroup,
} from "react-icons/fa";
import {
  collection,
  doc,
  getDocs,
  increment,
  limit,
  query,
  updateDoc,
} from "firebase/firestore";
import { db } from "../Firebase/firebase";
import {
  camelize,
  spacePascalCase,
  toPascalCase,
} from "../General/generalFunctions";
import { AccordionItemButton } from "react-accessible-accordion";
import { DevDocuLogo } from "../Components/DevDocuLogo";

type Props = { navigationItems?: NavigationSection[], projectLogo?: any };

export default function SideNavigation({
  navigationItems = mockNavigationSection,
  projectLogo,
}: Props) {
  const screenSize = useSelector(
    (state: any) => state.rootReducer.appReducer.screenSize
  );

  const [liked, setLiked] = useState(false);
  const [likes, setLikes] = useState<null | number>(null);
  const [hidden, setHidden] = useState(false);

  const activeSection = useSelector(
    (state: any) => state.rootReducer.appReducer.highlightedSection
  );

  const screenWidth = useSelector((state: any) => {
    return state.rootReducer.appReducer.screenWidth.payload;
  });

  const appSbxRef = collection(db, "appSbx");
  const q = query(appSbxRef, limit(1));
  const fetchAppSbx = async () => {
    try {
      const querySnapshot = await getDocs(q);
      const appSbx = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // setLikes()
      setLikes((appSbx[0] as { id: string; likes: number }).likes);
    } catch (error) {
      console.error("Error fetching posts: ", error);
    }
  };

  const incrementLikes = async () => {
    try {
      // Reference to the document you want to update
      const appSbxDocRef = doc(db, "appSbx", "q6JZ78xXwSQt5DY0BtQF"); // Replace "DOCUMENT_ID" with the actual document ID

      // Increment the 'likes' field by 1
      await updateDoc(appSbxDocRef, {
        likes: increment(1),
      });
    } catch (error) {
      console.error("Error updating likes: ", error);
    }
  };

  const decrementLikes = async () => {
    try {
      // Reference to the document you want to update
      const appSbxDocRef = doc(db, "appSbx", "q6JZ78xXwSQt5DY0BtQF"); // Replace "DOCUMENT_ID" with the actual document ID

      // Increment the 'likes' field by 1
      await updateDoc(appSbxDocRef, {
        likes: increment(-1),
      });
    } catch (error) {
      console.error("Error updating likes: ", error);
    }
  };

  useEffect(() => {
    fetchAppSbx();
  }, []);

  return (
    <div
      className={`sticky absolute top-[70px] h-full ${
        screenWidth === ScreenWidth.MOBILE || screenWidth === ScreenWidth.SM || screenWidth === ScreenWidth.MD || screenWidth === ScreenWidth.LG
          ? "w-[0px]"
          : hidden
          ? ""
          : "w-[450px]"
      }`}
    >
      {!(screenWidth === ScreenWidth.MOBILE || screenWidth === ScreenWidth.SM || screenWidth === ScreenWidth.MD || screenWidth === ScreenWidth.LG)  ? (
        !hidden ? (
          <div className="overflow-x-hidden transition-width p-3 py-10">
            <div className="p-3 flex items-center gap-3 border-neutral-800">
              <img src={LogoRedux} alt="Redux Logo" className=" h-[2rem]" />{" "}
              Redux Toolkit
              <Tooltip content="Collapse" placement="right" closeDelay={0}>
                <Button
                  onClick={() => setHidden(true)}
                  isIconOnly
                  className="m-auto"
                  variant="light"
                >
                  <FaChevronLeft />
                </Button>
              </Tooltip>
            </div>
            <div className="p-1 text-gray-400 text-sm">Jump to</div>
            <div className="flex flex-col ">
              {navigationItems.map((item, key) => {
                return (
                  <Accordion selectionMode="multiple" isCompact variant="light">
                    <AccordionItem
                      key={key}
                      aria-label={item.sectionGroup}
                      title={spacePascalCase(toPascalCase(item.sectionGroup))}
                    >
                      <div className="flex flex-col pb-2 gap-2 pl-3">
                        {item.sectionNames.map((section, key) => {
                          return (
                            <Link
                              href={"#" + section}
                              key={key}
                              className={`${
                                activeSection.payload === section
                                  ? "text-white"
                                  : "text-blue-600"
                              } transition-all`}
                            >
                              {spacePascalCase(toPascalCase(section))}
                            </Link>
                          );
                        })}
                      </div>
                    </AccordionItem>
                  </Accordion>
                );
              })}
              <Link
                href="#structure"
                className={`${
                  activeSection.payload === "structure"
                    ? "text-white"
                    : "text-blue-600"
                } transition-all ml-2 justify-start text-md mt-2`}
              >
                Structure
              </Link>
              <Link
                href="#snippets"
                as={Link}
                className={`${
                  activeSection.payload === "snippets"
                    ? "text-white"
                    : "text-blue-600"
                } transition-all ml-2 justify-start p-0 text-md mt-4`}
              >
                Snippets
              </Link>
              <Link
                href="#templates"
                as={Link}
                className={`${
                  activeSection.payload === "templates"
                    ? "text-white"
                    : " text-blue-600"
                } transition-all ml-2 justify-start p-0 text-md mt-4`}
              >
                Templates
              </Link>
              <div className="p-2 flex flex-row gap-2 items-center mt-4">
                <Button
                  isIconOnly
                  color="danger"
                  onClick={() => {
                    setLiked(!liked);
                    if (liked) {
                      decrementLikes();
                    } else {
                      incrementLikes();
                    }
                  }}
                  className="hover:scale-[1.4]"
                >
                  {liked ? <FaHeart fontSize={"20px"} /> : <FaRegHeart />}
                </Button>
                <div className="font-bold">
                  {likes ? likes + (liked ? 1 : 0) : ""}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-3 h-[300px] flex flex-col gap-3">
            <Link>
              <Tooltip content="Expand" placement="right" closeDelay={0}>
                <Button
                  isIconOnly
                  onClick={(hidden) => {
                    setHidden(!hidden);
                  }}
                >
                  <FaChevronRight />
                </Button>
              </Tooltip>
            </Link>
            <Link href="#">
              <Tooltip content="Top" placement="right" closeDelay={0}>
                <Button isIconOnly>
                  {
                    projectLogo ? 
                    <img src={projectLogo} className="p-2"/>
                    : <FaHome/>
                  }
                  </Button>
              </Tooltip>
            </Link>
            <Link href="#documentation">
              <Tooltip content="Documentation" placement="right" closeDelay={0}>
                <Button isIconOnly>
                  <FaBook />
                </Button>
              </Tooltip>
            </Link>
            <Link href="#structure">
              <Tooltip content="Structure" placement="right" closeDelay={0}>
                <Button isIconOnly>
                  <FaNetworkWired />
                </Button>
              </Tooltip>
            </Link>
            <Link href="#snippets">
              <Tooltip content="Snippets" placement="right" closeDelay={0}>
                <Button isIconOnly>
                  <FaPuzzlePiece />
                </Button>
              </Tooltip>
            </Link>
            <Link href="#templates">
              <Tooltip content="Templates" placement="right" closeDelay={0}>
                <Button isIconOnly>
                  <FaRegObjectGroup />
                </Button>
              </Tooltip>
            </Link>
            <Link>
              <Tooltip content="Like" placement="right" closeDelay={0}>
                <Button
                  color="danger"
                  isIconOnly
                  className="flex flex-col"
                  onClick={() => {
                    setLiked(!liked);
                    if (liked) {
                      decrementLikes();
                    } else {
                      incrementLikes();
                    }
                  }}
                >
                  {liked ? <FaHeart /> : <FaRegHeart />}
                </Button>
              </Tooltip>
            </Link>
            <div className=" text-[10px] flex items-start justify-center -translate-y-[60%]">
              {likes ? likes + (liked ? 1 : 0) : ""}
            </div>
          </div>
        )
      ) : null}
    </div>
  );
}
