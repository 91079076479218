import { RefObject } from "react";
import { ReactFlowInstance } from "reactflow";
import {
  DevdocuDocumentationPage,
  DevdocuTypes,
} from "../Mock/rtkdocumentation";
import { NavigationSection } from "../Mock/rtkDocumentationNavigation";
import JSZip from "jszip";

export function isValidEmail(email: string) {
  // Regular expression pattern for a basic email validation
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
}

export function getCollectiveNodesCenter(nodes: any) {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  nodes.forEach((node: any) => {
    minX = Math.min(minX, node.position.x);
    minY = Math.min(minY, node.position.y);
    maxX = Math.max(maxX, node.position.x + node.width);
    maxY = Math.max(maxY, node.position.y + node.height);
  });

  return {
    x: (minX + maxX) / 2,
    y: (minY + maxY) / 2,
  };
}

export function getGraphZoomLevel(
  nodes: any,
  reactFlowInstance: ReactFlowInstance,
  reactflowWrapperRef: RefObject<HTMLDivElement>
) {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  nodes.forEach((node: any) => {
    minX = Math.min(minX, node.position.x);
    minY = Math.min(minY, node.position.y);
    maxX = Math.max(maxX, node.position.x + node.width);
    maxY = Math.max(maxY, node.position.y + node.height);
  });

  // Calculate the width and height of the bounding box
  const boundingBoxWidth = maxX - minX;
  const boundingBoxHeight = maxY - minY;
  // Calculate the desired dimensions of the viewport (you can customize this)
  const viewportWidth = reactflowWrapperRef.current?.clientWidth;
  const viewportHeight = reactflowWrapperRef.current?.clientHeight;

  if (viewportHeight === undefined || viewportWidth === undefined) {
    return 10;
  }

  // Calculate the required zoom level
  const zoomLevel = Math.min(
    viewportWidth / boundingBoxWidth,
    viewportHeight / boundingBoxHeight
  );
  return zoomLevel;
}

export const findFileContent = (nodeName: string, node: any): string => {
  if (node.type === "file" && node.name === nodeName) {
    return node.content || null;
  }
  if (node.type === "directory" && node.children) {
    for (let child of node.children) {
      const foundContent = findFileContent(nodeName, child);
      if (foundContent) return foundContent;
    }
  }
  return "";
};

export const findFile = (nodeName: string, node: any) => {
  if (node.type === "file" && node.name === nodeName) {
    return node || null;
  }
  if (node.type === "directory" && node.children) {
    for (let child of node.children) {
      const foundFile: any = findFile(nodeName, child);
      if (foundFile) return foundFile;
    }
  }
  return null;
};

const findFileDescription = (nodeName: string, node: any): string => {
  if (node.type === "file" && node.name === nodeName) {
    return node.description || null;
  }
  if (node.type === "directory" && node.children) {
    for (let child of node.children) {
      const foundContent = findFileDescription(nodeName, child);
      if (foundContent) return foundContent;
    }
  }
  return "";
};

export const getFileContentByName = (
  fileName: string,
  dataTree: any
): string | null => {
  return findFileContent(fileName, dataTree);
};

export const getFileDescriptionByName = (
  fileName: string,
  dataTree: any
): string | null => {
  const fileDescription = findFileDescription(fileName, dataTree);
  return findFileDescription(fileName, dataTree);
};

export function camelize(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export function toPascalCase(inputString: string) {
  // Split the input string by spaces, underscores, or dashes
  const words = inputString.split(/[\s_-]/);

  // Capitalize the first letter of each word and join them together
  const pascalCaseString = words
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join("");

  return pascalCaseString;
}

export function spacePascalCase(input: string) {
  return input.replace(/([a-z])([A-Z])/g, "$1 $2");
}

export function pageToNavigationSections(
  page: DevdocuDocumentationPage
): NavigationSection[] {
  const navigationSectionsMap: Map<string, NavigationSection> = new Map();

  for (const section of page.content) {
    if (section.type === DevdocuTypes.DEVDOCU_SECTIONTYPE) {
      const sectionGroup = section.sectionGroup || ""; // Assuming the sectionGroup is present and not undefined
      if (navigationSectionsMap.has(sectionGroup)) {
        // If a NavigationSection with the same group already exists, update it
        const existingSection = navigationSectionsMap.get(sectionGroup);
        if (existingSection) {
          existingSection.sectionNames.push(section.sectionName);
        }
      } else {
        // Create a new NavigationSection if it doesn't exist
        navigationSectionsMap.set(sectionGroup, {
          sectionNames: [section.sectionName],
          sectionGroup,
        });
      }
    }
  }

  // Convert the Map values to an array
  const navigationSections: NavigationSection[] = Array.from(
    navigationSectionsMap.values()
  );

  return navigationSections;
}

export function getElementsFromArray(arr: any, n: number, m: number) {
  // Ensure n is within array boundaries
  n = Math.max(n, 0);

  // Ensure m is within array boundaries
  m = Math.min(m, arr.length - 1);

  // Check for valid input indices
  if (n > m) {
    return []; // Return an empty array for invalid input
  }

  // Use Array.slice() to extract elements from n to m (inclusive)
  return arr.slice(n, m + 1);
}

function separateAndZipJSONObjects(jsonArray: any) {
  const zip = new JSZip();

  jsonArray.forEach((jsonObj: any, index: any) => {
    const jsonFileName = `object_${index + 1}.json`;
    const jsonData = JSON.stringify(jsonObj, null, 2); // Convert JSON object to string with formatting
    zip.file(jsonFileName, jsonData);
  });

  return zip;
}

export function getPathLength(pathString: any) {
  const tempSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  const tempPath = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "path"
  );
  tempPath.setAttribute("d", pathString);
  tempSvg.appendChild(tempPath);
  return tempPath.getTotalLength();
}
