import {
  Card,
  CardHeader,
  Divider,
  CardBody,
  CardFooter,
  Link,
  Image,
  Button,
} from "@nextui-org/react";
import Monalisa from "../../../Resources/monalisaSquare.jpg";
import { FaAdversal, FaTerminal } from "react-icons/fa";
import { useState } from "react";
import { generate } from "random-words";

export type DevdocuTemplateType = {
  title: string;
  description: string;
  level: "basic" | "intermediate" | "complex";
  githubLink: string;
};

const mockTemplate: DevdocuTemplateType = {
  title: "Mock",
  description: "Mock description",
  level: "basic",
  githubLink: "https://github.com",
};

type TemplateCardProps = {
  template?: DevdocuTemplateType;
};

export default function TemplateCard({
  template = mockTemplate,
}: TemplateCardProps) {
  return (
    <Card className={`transition-all`}>
      <CardHeader className="flex flex-row items-start justify-between p-3">
        <div>
          <small className="text-default-500">
            {template.level.toUpperCase()}
          </small>
          <h4 className="font-bold text-large">{template.title}</h4>
        </div>
        <div className=" text-4xl pr-6 py-2">
          <FaTerminal />
        </div>
      </CardHeader>
      <Divider />
      <CardBody className="p-2">
        <div className="flex flex-col">
          <div className="p-2">{template.description}</div>
          <div className="flex flex-row gap-3">
            <Link href={template.githubLink}>
              <Button color="primary">Open</Button>
            </Link>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
