import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Link,
} from "@nextui-org/react";
import "../App.css";
import {
  DevdocuParagraph,
  DevdocuTypes,
} from "../Mock/rtkdocumentation";
import DOMPurify from "dompurify";

const mockContent: DevdocuParagraph = {
  content: [
    {
      type: DevdocuTypes.DEVDOCU_NORMALTEXT,
      text: "Mock text. ",
    },
    {
      type: DevdocuTypes.DEVDOCU_POPOVER,
      text: "Foo",
      popoverText: "Bar",
    },
  ],
  type: DevdocuTypes.DEVDOCU_DEFAULTPARAGRAPH,
};

interface Props {
  content?: DevdocuParagraph;
}

const allowedTags = ["b", "i", "u", "strong", "em", "mark", "sub", "sup", "code"]; // Add more allowed tags as needed

function InfoParagraph({ content = mockContent }: Props) {
  return (
    <p>
      {content.content.map((item: any, index: any) => {
        //TODO: Analyze security concerns here with allowing tags. DOMPurify seems to be a good solution for now.
        const sanitizedText = DOMPurify.sanitize(item.text, {
          ALLOWED_TAGS: allowedTags,
        });

        const sanitizedPopoverText = DOMPurify.sanitize(item.popoverText, {
          ALLOWED_TAGS: allowedTags,
        });

        return item.type === DevdocuTypes.DEVDOCU_POPOVER ? (
          <Popover placement="top" key={index}>
            <PopoverTrigger className="cursor-pointer">
              <Link className="px-1 bg-opacity-50 text-white border rounded-lg bg-slate-600 ">
                <span
                  dangerouslySetInnerHTML={{ __html: sanitizedText }}
                ></span>
              </Link>
            </PopoverTrigger>
            <PopoverContent className="dark text-foreground w-[20em]">
              <div className="px-1 py-2">
                <div
                  className="text-small font-bold"
                  dangerouslySetInnerHTML={{ __html: sanitizedText }}
                ></div>
                <div
                  className="text-small"
                  dangerouslySetInnerHTML={{ __html: sanitizedPopoverText }}
                ></div>
              </div>
            </PopoverContent>
          </Popover>
        ) : (
          <span
            key={index}
            dangerouslySetInnerHTML={{ __html: sanitizedText }}
          />
        );
      })}
    </p>
  );
}

export default InfoParagraph;
