/* ------------------------------ React Imports ----------------------------- */
import React, { useEffect, useRef } from "react";

/* ---------------------------- Features Imports ---------------------------- */
import { Page } from "./Pages/Page";
import { ScreenSize } from "./General/types";
import {
  setHighlightedSection,
  setScreenSize,
  setScreenWidth,
} from "./Redux/appSlice";

import Home from "./Pages/Home";
import About from "./Pages/About";
import NoMatch from "./Pages/NoMatch";
import Layout from "./Pages/Layout";
import Sandbox from "./Pages/Sandbox";
import store from "./Redux/store";

/* ----------------------------- Package Imports ---------------------------- */
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Button, NextUIProvider } from "@nextui-org/react";
import { Provider, useDispatch, useSelector } from "react-redux";
import ScrollToTop from "react-scroll-to-top";
import { useMediaQuery } from "react-responsive";
import IntroPage from "./Pages/IntroPage";
import { FaArrowUp } from "react-icons/fa";
import { ScreenWidth } from "./General/sizes";

/* ---------------------------------- Main ---------------------------------- */
function App() {
  /* ---------------------------- Screen size check --------------------------- */
  const dispatch = useDispatch();

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const isBigScreen = useMediaQuery({ minWidth: 1824 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  const isMobile = useMediaQuery({ minWidth: "0px", maxWidth: "639px" });
  const isSm = useMediaQuery({ minWidth: "640px", maxWidth: "767px" });
  const isMd = useMediaQuery({ minWidth: "768px", maxWidth: "1023px" });
  const isLg = useMediaQuery({ minWidth: "1024px", maxWidth: "1279px" });
  const isXl = useMediaQuery({ minWidth: "1280px", maxWidth: "1535px" });
  const isXxl = useMediaQuery({ minWidth: "1536px" });

  useEffect(() => {
    if (isMobile) {
      dispatch(setScreenWidth({ payload: ScreenWidth.MOBILE }));
    } else if (isSm) {
      dispatch(setScreenWidth({ payload: ScreenWidth.SM }));
    } else if (isMd) {
      dispatch(setScreenWidth({ payload: ScreenWidth.MD }));
    } else if (isLg) {
      dispatch(setScreenWidth({ payload: ScreenWidth.LG }));
    } else if (isXl) {
      dispatch(setScreenWidth({ payload: ScreenWidth.XL }));
    } else if (isXxl) {
      dispatch(setScreenWidth({ payload: ScreenWidth.XXL }));
    } else {
      dispatch(setScreenWidth({ payload: ScreenWidth.MOBILE }));
    }
  }, [isSm, isMd, isLg, isXl, isXxl, isMobile]);

  useEffect(() => {
    const screenSize = [];

    if (isDesktopOrLaptop) screenSize.push(ScreenSize.Desktop);
    if (isBigScreen) screenSize.push(ScreenSize.BigScreen);
    if (isTabletOrMobile) screenSize.push(ScreenSize.Mobile);

    dispatch(setScreenSize(screenSize));
  }, [dispatch, isDesktopOrLaptop, isBigScreen, isTabletOrMobile]);

  /* --------------------- Active Section for Highlighting -------------------- */
  // Your current useSelector call:
  const highlightedSection = useSelector(
    (state: any) => state.rootReducer.appReducer.highlightedSection
  );

  // Create a ref and update it whenever highlightedSection changes
  const highlightedSectionRef = useRef(highlightedSection);

  useEffect(() => {
    highlightedSectionRef.current = highlightedSection;
  }, [highlightedSection]);

  const handleScroll = () => {
    document.querySelectorAll("section").forEach((section) => {
      const rect = section.getBoundingClientRect();
      if (rect.top <= 100 && rect.bottom >= 100) {
        // Use the ref's current value here instead of highlightedSection
        if (
          highlightedSectionRef.current.payload !== section.id &&
          section.id !== ""
        ) {
          dispatch(setHighlightedSection({ payload: section.id }));
          return; // This will exit the forEach loop
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <BrowserRouter>
      <NextUIProvider>
        <ScrollToTop
          smooth
          component={
            <div className="text-black font-black text-center items-center justify-center flex">
              <FaArrowUp />
            </div>
          }
          style={{ zIndex: 10000 }}
        />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="reduxtoolkit" element={<Page />} />
            <Route path="*" element={<NoMatch />} />
            <Route path="sbx" element={<Sandbox />} />
            <Route path="reduxtoolkit-introduction" element={<IntroPage />} />
          </Route>
        </Routes>
      </NextUIProvider>
    </BrowserRouter>
  );
}

export default App;
