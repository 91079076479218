import { Directory } from "../Components/DataTree";

export const mockDataDataTree: Directory = {
  type: "directory",
  name: "PROJECT",
  children: [
    {
      type: "file",
      name: "index.tsx",
      content:
        "import React from 'react';\n\nexport const App = () => {\n  return <div>Hello World!</div>;\n};",
      description: "Lorem Ipsum Descriptionology 1",
    },
    {
      type: "file",
      name: "app.tsx",
      content:
        "import React from 'react';\n\nconst App: React.FC = () => {\n  return <div>App component</div>;\n};\n\nexport default App;",
      description: "Lorem Ipsum Descriptionology 2",
      highlighted: [1],
    },
    {
      type: "directory",
      name: "components",
      children: [
        {
          type: "file",
          name: "Header.tsx",
          content:
            "import React from 'react';\n\nconst Header: React.FC = () => {\n  return <header>Long Header Hello Hello</header>; console.log('Hey Hey'))\n};\n\nexport default Header;",
          description: "Lorem Ipsum Descriptionology 3",
          highlighted: [2, 4],
        },
        {
          type: "file",
          name: "Footer.tsx",
          content:
            "import React from 'react';\n\nconst Footer: React.FC = () => {\n  return <footer>Footer Component</footer>;\n};\n\nexport default Footer;",
          description: "Lorem Ipsum Descriptionology 4",
        },
      ],
    },
  ],
};
