import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

//Store for Devdocu

const store = configureStore({
  reducer: {
    rootReducer
  },
});

export default store;
