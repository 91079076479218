import React, { CSSProperties } from 'react'

type Props = {
  title: string;
  isSticky?: boolean;
  scrollShadow?: boolean;
  outlineText?: boolean
  className?: string;
  style?: CSSProperties;
};

export default function Title({ title, isSticky, scrollShadow, className, style, outlineText }: Props) {
  const defaultClassName = 'text-4xl font-bold flex items-middle justify-center py-2 pt-4';
  const defaultStyles = {};

  // Determine if the "sticky" class and boxShadow should be applied based on props
  const stickyClassName = isSticky ? 'sticky top-[70px] bg-black z-[20]' : '';
  const outlineTextClass = outlineText ? 'text-stroke text-black' : '';
  const boxShadowStyle = scrollShadow
    ? {
        boxShadow:
          '-5px 0 5px -5px rgba(0, 0, 0, 0), 0 5px 5px -5px rgba(0, 0, 0, 0), 0 10px 7px 5px rgba(0, 0, 0, 1)',
      }
    : {};

  // Merge all classNames and styles
  const mergedClassName = `${defaultClassName} ${stickyClassName} ${outlineTextClass} ${className || ''}`;
  const mergedStyles = { ...defaultStyles, ...boxShadowStyle, ...style };

  return (
    <div className={mergedClassName} style={mergedStyles}>
      {title}
    </div>
  );
}
