import React from 'react';
import devDocuLogo from '../Resources/logo-devdocu.svg'; // Replace with the correct path to your SVG file
import devDocuLogoBlack from '../Resources/logo-devdoc-black.svg'

type Props = { height?: string; width?: string; dark?: boolean};

export const DevDocuLogo = ({ height = '25px', width = '25px', dark = false}: Props) => {
  // Define the SVG viewBox attribute based on size

  return (
    <>
    {
        dark?
        <img src={devDocuLogoBlack} height={height} width={width}/> :
        <img src={devDocuLogo} height={height} width={width}/>
    }
    </>
  );
};
