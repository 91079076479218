import {
  Button,
  Card,
  CardBody,
  Link,
  Spinner,
  Switch,
  Textarea,
} from "@nextui-org/react";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import ReactFlow from "reactflow";
import { CustomNode } from "../Graph/CustomNode";
import { NAVBAR_HEIGHT_PX } from "../General/constants";
import bgShadow from "../Resources/bg-shadows.png";
import Title from "../Components/Title";
import Subheader from "../Components/Subheader";
import { addDoc, collection } from "firebase/firestore";
import ReCAPTCHA from "react-google-recaptcha";
import { FaMailBulk } from "react-icons/fa";
import { db } from "../Firebase/firebase";
import { isValidEmail } from "../General/generalFunctions";
import { RECAPTCHA_SITE_KEY } from "../secrets/secrets";
import { IS_DEVELOPMENT } from "../secrets/secrets";
import { DevDocuLogo } from "../Components/DevDocuLogo";
import devDocuLogo from "../../Resources/logo-devdocu.png";

/* ----------------------------- Testing Grounds ---------------------------- */

const aboutProblemQuotes = [
  {
    TextBeforeHighlight: "I just want ",
    TextHighlight: "something quick that works",
    TextAfterHighlight: ", get to the point.",
  },
  {
    TextBeforeHighlight: "I'm lost, and ",
    TextHighlight: "I don't know what I need",
    TextAfterHighlight: ".",
  },
  {
    TextBeforeHighlight: "Why are there ",
    TextHighlight: "so many different tutorials",
    TextAfterHighlight: " that explain the same concept?",
  },
  {
    TextBeforeHighlight: "Why all this text? This could've been ",
    TextHighlight: "more concise",
    TextAfterHighlight: ".",
  },
  {
    TextBeforeHighlight: "If only coding could be more like ",
    TextHighlight: "building lego sets",
    TextAfterHighlight: ".",
  },
  {
    TextBeforeHighlight: "This all is a bit ",
    TextHighlight: "hard to visualize",
    TextAfterHighlight: " in my head.",
  },
  {
    TextBeforeHighlight: "Dang, I ",
    TextHighlight: "forgot what the framework code looks like",
    TextAfterHighlight: " for component X again.",
  },
];

type Props = {};

export default function About({}: Props) {
  const [isCaptchaVerified, setCaptchaVerified] = useState(false);

  function onChange(value: any) {
    if (value) {
      setCaptchaVerified(true);
    }
  }

  function onInvalid() {
    setCaptchaVerified(false);
  }

  async function submitMessageHandler() {
    if (isCaptchaVerified || IS_DEVELOPMENT) {
      const docRef = await addDoc(collection(db, "contactMessages"), {
        name: contactFormName,
        emailAddress: contactFormEmail,
        message: contactFormMessage,
        timeSubmitted: Date.now(),
      });
      console.log("Message sent");
      setMessageSent(true);
      setTimeout(() => {
        setMessageSendFakeLoading(false);
      }, 800);
    } else {
      alert("Please verify that you are not a robot");
    }
  }

  const [contactFormName, setContactFormName] = useState("");
  const [contactFormEmail, setContactFormEmail] = useState("");
  const [contactFormMessage, setContactFormMessage] = useState("");
  const [isMessageSent, setMessageSent] = useState(false);
  const [messageSendFakeLoading, setMessageSendFakeLoading] = useState(true);
  const [validEmail, setValidEmail] = useState(true);

  function handleChangeContactFormName(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    setContactFormName(event.target.value);
  }

  function handleChangeContactFormEmail(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    setContactFormEmail(event.target.value);
    if (
      event.target.value !== "" ||
      event.target.value === undefined ||
      event.target.value === null
    ) {
      setValidEmail(isValidEmail(event.target.value));
    } else {
      setValidEmail(true);
    }
  }

  function handleChangeContactFormMessage(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    setContactFormMessage(event.target.value);
  }

  useEffect(() => {
    // Check if there is a hash in the URL (e.g., #section1)
    const hash = window.location.hash;
    if (hash) {
      // Use JavaScript to scroll to the section
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <div
      id="main"
      className={`flex flex-col items-center justify-center gap-6 sm:px-[20%] px-[5%] py-[10vh]`}
      style={{
        backgroundImage: `url(${bgShadow})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      }}
    >
      <div id="about-title" className="text-4xl font-bold">
        "What's this all <span className="text-5xl text-blue-600">about</span>
        ?"
      </div>
      <div id="about-introduction">
        <Subheader
          text={
            "Documentation can be hard to read, no matter if you're a beginner, or an expert."
          }
        />
      </div>
      <div
        id="about-problem-cards"
        className="flex flex-row flex-wrap gap-3 justify-center"
      >
        {aboutProblemQuotes.map((quote, index) => {
          return (
            <Card className="sm:w-[25vw] w-[40vw] sm:max-w-sm" key={index}>
              <CardBody>
                <p>
                  "{quote.TextBeforeHighlight}
                  <span className="text-blue-600 text-xl font-bold">
                    {quote.TextHighlight}
                  </span>
                  {quote.TextAfterHighlight}"
                </p>
              </CardBody>
            </Card>
          );
        })}
      </div>
      <div className="px-0 flex flex-col gap-4 sm:px-[7vw]">
        <div>
          These problems were the reason for the creation of this site. Reading,
          and jumping from documentation to documentation (or from tutorial to
          tutorial) has always required a lot of time. Coding could be more like
          building lego sets, given the right documentation. How nice would it
          be if there was some cheat <i>sheet</i>(not a book) to refer to?
          Ultimately, the goal of this project is to create code documentation,
          which is human, for humans.
        </div>
        <div>
          I'm currently a solo developer working on this project. I've always
          been someone who's loved communicating ideas, and I found personally
          that there's a lot of documentation out there which could be written,
          and designed better, may it be through interactive, visual, or both
          ways. I hope that as time goes, more and more developers will find the
          use in this new form of documentation and want to contribute to this
          project. There's always room for improvement, so do leave some
          comments, or feedback!
        </div>
        <div>Happy coding,</div>
        <div className="flex flex-col">
          -Sudohaxe
          <span className="text-xs text-gray-600">Founder of DevDocu</span>
        </div>
      </div>
      <section
        id="contact"
        className="flex items-center justify-center py-[50px] flex-col navbar-scroll-offset"
      >
        <div className="text-4xl p-10 text-center">
          Do you have suggestions,
          <br /> or feedback? your opinion matters.
        </div>

        <div className="w-full max-w-md flex flex-col gap-1">
          <Textarea
            label="Name (optional)"
            labelPlacement="inside"
            placeholder="Enter your name"
            maxRows={1}
            onChange={handleChangeContactFormName}
          />
          <Textarea
            label="Email (optional, this will help me get back to you)"
            labelPlacement="inside"
            placeholder="Enter your email"
            maxRows={1}
            onChange={handleChangeContactFormEmail}
            validationState={validEmail ? "valid" : "invalid"}
            errorMessage={
              validEmail ? "" : "Please enter a valid email address"
            }
          />
          <Textarea
            label="Message"
            labelPlacement="inside"
            placeholder="Enter your message here"
            maxRows={4}
            onChange={handleChangeContactFormMessage}
          />
        </div>
        <div
          className={`p-3 ${
            !isCaptchaVerified ? "border-red-500" : "border-transparent"
          } border border-small mt-3`}
        >
          <ReCAPTCHA
            sitekey={RECAPTCHA_SITE_KEY}
            onChange={onChange}
            onErrored={onInvalid}
            onExpired={onInvalid}
            style={{ borderRadius: "10%" }}
            className=""
          />
          {!isCaptchaVerified && (
            <small className=" text-red-500">
              Please verify that you are not a robot
            </small>
          )}
        </div>
        {isMessageSent ? (
          <div className="mt-3 flex flex-col items-center">
            {messageSendFakeLoading ? (
              <Spinner label="Sending.."></Spinner>
            ) : (
              <div className="text-2xl text-blue-600 animate-appearance-in">
                Message sent! Thank you for your feedback 😄
              </div>
            )}
          </div>
        ) : (
          <Button
            size="lg"
            color="primary"
            className="text-xl font-semibold mt-3"
            onClick={submitMessageHandler}
            isDisabled={!((isCaptchaVerified && validEmail) || IS_DEVELOPMENT)}
          >
            <FaMailBulk />
            Send
          </Button>
        )}
      </section>
    </div>
  );
}
