import { collection, query, orderBy, limit, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../Firebase/firebase";
import Title from "../Components/Title";
import {
  Button,
  Divider,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import Graph from "../Graph/Graph";
import {
  rtkDataRoutes,
  rtkMainEdges,
  rtkMainNodes,
} from "../Mock/rtkmainGraphData";
import { ResizableBox } from "react-resizable";
import { Resizable } from "react-resizable";
import InfoParagraph from "../Components/InfoParagraph";
import { mockInfoParagraphData } from "../Mock/mockInfoParagraphData";
import BestPracticesSection from "../Sections/BestPracticesSection";
import InformationSection from "../Components/InformationSection";
import DataTree from "../Components/DataTree";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { exampleCode } from "../Mock/exampleCode";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

import "../App.css";
import FileExplorer from "../Components/FileExplorer";
import bgShadow from "../Resources/bg-shadows.png";
import { rtkDocumentation } from "../Mock/rtkdocumentation";
import { mockRtkSnippets } from "../Mock/MockSnippets";
import { mockIndex } from "../Mock/MockSearchIndex";
import JSONZipDownloadButton from "../Sandbox/JSONZipDownloadButton";

type Props = {};

export default function Sandbox({}: Props) {
  const postsRef = collection(db, "posts");
  const q = query(postsRef, limit(10));

  const [posts, setPosts] = useState<any>(null);

  const fetchPosts = async () => {
    try {
      const querySnapshot = await getDocs(q);
      const posts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPosts(posts);
    } catch (error) {
      console.error("Error fetching posts: ", error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const [width, setWidth] = useState(200);
  const [height, setHeight] = useState(200);
  // On top layout
  const onResize = (event: any, { node, size, handle }: any) => {
    setWidth(size.width);
    setWidth(size.height);
  };

  return (
    <div
      className="p-10"
      style={{
        backgroundImage: `url(${bgShadow})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Title title="SBX" />
      <Divider />
      <div id="sbx">
        <Title title="Firebase" />
        <Divider />
        {posts !== null ? (
          <section id="posts">
            {posts.map((post: any) => {
              return (
                <div key={post.id}>
                  <h4>{post.title}</h4>
                  <small>{post.id}</small>
                </div>
              );
            })}
          </section>
        ) : (
          <div>No Posts</div>
        )}
        <Divider />
        <Title title="Popover" />
        <Divider />
        <section id="popover">
          <InfoParagraph />
        </section>
        <Divider />
        <Title title="Best Practices" />
        <Divider />
        <section id="best-practices">
          <BestPracticesSection />
        </section>
        <Divider />
        <Title title="Information Section" />
        <Divider />
        <section id="information-section">
          <div id="information-section-simulate-div-size" className="m-[20px]">
            <div className="w-[500px]">
              <InformationSection />
            </div>
          </div>
        </section>
        <Divider />
        <Title title="Likes" />
        <Divider />
        <section id="likes">TODO</section>
        <Divider />
        <Title title="Data Tree" />
        <Divider />
        <section id="Data Tree">
          {/* <FileExplorer height={'300px'}/> */}
        </section>
        <Divider />
        <Button onClick={()=>{console.log(rtkDocumentation)}}>
          Print RTKDoc JSON
        </Button>
        <Button onClick={()=>{console.log(mockRtkSnippets)}}>
          Print RTKSnippets JSON
        </Button>
        <Button onClick={()=>{console.log(mockIndex)}}>
          Print Mock Index
        </Button>
        <JSONZipDownloadButton/>
      </div>
    </div>
  );
}
