import { useState, useMemo, useEffect, useRef,
} from "react";
import ReactFlow, { Background, BackgroundVariant, Controls, useNodesState,
} from "reactflow";
import "reactflow/dist/style.css";
import { CustomNode } from "./CustomNode";
import CustomConnectionLine from "./CustomConnectionLine";
import ButtonEdge from "./ButtonEdge";
import { rtkMainEdges, rtkMainNodes } from "../Mock/rtkmainGraphData";
import debounce from "lodash.debounce";
import { setChangesDetected } from "../Redux/appSlice";
import { useDispatch } from "react-redux";
import { setDataRoutes } from "../Redux/appSlice";
import { rtkDataRoutes } from "../Mock/rtkmainGraphData";

type Props = {nodes: any, edges: any, dataRoutes: any};

export default function HomeGraph({nodes, edges, dataRoutes}: Props) {
  /* ----------------------------------- Sbx ---------------------------------- */
  const [nodesState, setNodesState, onNodesStateChange] = useNodesState(nodes);

  /* ----------------------------- Local Variables ---------------------------- */
  const reactFlowWrapperRef = useRef<HTMLDivElement>(null);

  /* ---------------------------------- Redux --------------------------------- */
  const dispatch = useDispatch();
  useEffect(()=>{
  dispatch(setDataRoutes(dataRoutes));
  },[])

  /* --------------------------- Debouncing Changes --------------------------- */
  // i.e. handleNodesChange is called very frequently when a node changes its property. Wait until 0.5s after
  // the last call of change to properly tell the redux store that changes have been detected. ChangesDetected used to 
  // calculate the offset of the "data" going through the edges, since this was implemented using stroke offsets.
  const hasDispatchedChange = useRef(false); // Using ref to track if changes have been dispatched

const customHandleNodesChange = (newNodes: any) => {
    onNodesStateChange(newNodes);
    if (!hasDispatchedChange.current) {
        dispatch(setChangesDetected(true));
        hasDispatchedChange.current = true;
    }
    debouncedSetChangesToFalse();
};

const debouncedSetChangesToFalse = useMemo(() => 
    debounce(() => {
        dispatch(setChangesDetected(false));
        hasDispatchedChange.current = false; // Reset after debounce completes
    }, 500),
    [dispatch] 
);



  /* ---------- Force scroll when the user is hovering over the graph --------- */
  useEffect(() => {
    const handleScroll = (e: any) => {
      const delta = Math.sign(e.deltaY);
      window.scrollBy(0, 50 * delta);
    };
    const currentRef = reactFlowWrapperRef.current;
    if (currentRef) {
      currentRef.addEventListener('wheel', handleScroll);
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener('wheel', handleScroll);
      }
    };
  }, []);

  /* ------------------------ Edge Types and Node Types ----------------------- */
  const nodeTypes = useMemo(() => ({ customNode: CustomNode }), []);
  const edgeTypes = useMemo(() => ({ buttonedge: ButtonEdge}), [])

  /* ------------------------------- Main Return ------------------------------ */
  return (
      <ReactFlow
        nodes={nodesState}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        connectionLineComponent={CustomConnectionLine}
        nodesDraggable={false}
        fitView
        fitViewOptions={{ padding: 0 }}
        style={{ borderRadius: '5px'}}
        onNodesChange={customHandleNodesChange}
        zoomOnScroll={false}
        panOnDrag={false}
      >
      </ReactFlow>
  );
}
