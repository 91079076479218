import React, { useEffect, useMemo, useRef, useState } from "react";
import { Rnd } from "react-rnd";
import Graph from "./Graph";
import {
  rtkDataRoutes,
  rtkMainEdges,
  rtkMainNodes,
} from "../Mock/rtkmainGraphData";
import ReactFlow, {
  Background,
  BackgroundVariant,
  ReactFlowInstance,
  useEdgesState,
  useNodesState,
} from "reactflow";
import { CustomNode } from "./CustomNode";
import ButtonEdge from "./ButtonEdge";
import CustomConnectionLine from "./CustomConnectionLine";
import {
  getCollectiveNodesCenter,
  getGraphZoomLevel,
} from "../General/generalFunctions";
import { Button } from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import {
  FaAccessibleIcon,
  FaArrowAltCircleDown,
  FaArrowsAlt,
  FaCross,
  FaRegWindowMaximize,
  FaTimes,
} from "react-icons/fa";
import { setChangesDetected, setPictureInPicture } from "../Redux/appSlice";
import debounce from "lodash.debounce";

type Props = { nodes: any; edges: any; dataRoutes: any };

export default function PiPGraph({ nodes, edges, dataRoutes }: Props) {
  const pictureInPicture = useSelector(
    (state: any) => state.rootReducer.appReducer.pictureInPicture
  );
  const dispatch = useDispatch();

  const nodeTypes = useMemo(() => ({ customNode: CustomNode }), []);
  const edgeTypes = useMemo(() => ({ buttonedge: ButtonEdge }), []);

  const [nodesState, setNodesState, onNodesStateChange] = useNodesState(nodes);
  const [edgesState, setEdgesState, onEdgesStateChange] = useEdgesState(edges);

  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);

  const reactFlowWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentRef = reactFlowWrapperRef.current;
    setTimeout(focusGraph, 1000);
  }, []);

  function onInitReactFlow(
    reactFlowInstance: ReactFlowInstance<any, any>
  ): void {
    setReactFlowInstance(reactFlowInstance);
  }

  const focusGraph = () => {
    if (reactFlowInstance) {
      const nodes = reactFlowInstance.getNodes();
      const collectiveCenter = getCollectiveNodesCenter(nodes);
      const zoom =
        getGraphZoomLevel(nodes, reactFlowInstance, reactFlowWrapperRef) * 0.8;
      reactFlowInstance.setCenter(collectiveCenter.x, collectiveCenter.y, {
        zoom,
        duration: 1000,
      });
    }
  };

  const hasDispatchedChange = useRef(false); // Using ref to track if changes have been dispatched

  useEffect(() => {
    setTimeout(() => {
      focusGraph();
    }, 1000);
  }, [pictureInPicture]);
  const customHandleNodesChange = (newNodes: any) => {
    onNodesStateChange(newNodes);
    if (!hasDispatchedChange.current) {
      dispatch(setChangesDetected(true));
      hasDispatchedChange.current = true;
    }
    debouncedSetChangesToFalse();
  };

  const customHandleEdgesChange = (newEdges: any) => {
    onEdgesStateChange(newEdges);
  };

  const debouncedSetChangesToFalse = useMemo(
    () =>
      debounce(() => {
        dispatch(setChangesDetected(false));
        hasDispatchedChange.current = false; // Reset after debounce completes
      }, 500),
    [dispatch]
  );

  return (
    <>
      {pictureInPicture ? (
        <Rnd
          default={{
            x: 0,
            y: 0,
            width: 320,
            height: 200,
          }}
          onResizeStop={() => {
            focusGraph();
          }}
          dragHandleClassName="rnd-handle"
        >
          <div className=" flex items-center justify-center absolute h-[30px] w-[40px] rnd-handle right-1/2 translate-x-1/2 -translate-y-[100%] top-0 bg-neutral-600 border-sm rounded-tl-md rounded-tr-md">
            <FaArrowsAlt />
          </div>
          <div
            className="w-full h-full  overflow-hidden rounded-md border-small bg-black"
            ref={reactFlowWrapperRef}
          >
            <Button
              className="absolute top-0 right-0 m-3 z-[50]"
              isIconOnly
              size="sm"
              onClick={() => {
                dispatch(setPictureInPicture(false));
              }}
              variant="light"
              color="danger"
            >
              <FaTimes />
            </Button>
            <div className=" cursor-move rnd-handle h-[20px] w-[150px] bg-neutral-600 border-sm border-neutral-800 mx-auto -translate-y-1/2 rounded-md"></div>
            <ReactFlow
              nodes={nodesState}
              edges={edgesState}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              connectionLineComponent={CustomConnectionLine}
              nodesDraggable={true}
              fitView
              fitViewOptions={{ padding: 0.20 }}
              style={{ borderRadius: "5px" }}
              onNodesChange={customHandleNodesChange}
              onEdgesChange={customHandleEdgesChange}
              maxZoom={2}
              minZoom={0.2}
              zoomOnScroll={true}
              onInit={onInitReactFlow}
            >
              <Background variant={BackgroundVariant.Dots} />
            </ReactFlow>
          </div>
        </Rnd>
      ) : null}
    </>
  );
}
