import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Pagination,
  snippet,
  useDisclosure,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";

import Monalisa from "../../Resources/monalisaSquare.jpg";
import { FaCopy, FaHammer, FaHeart, FaRandom, FaSearch } from "react-icons/fa";
import { ResizableBox } from "react-resizable";
import { NAVBAR_HEIGHT_NUM } from "../General/constants";
import Title from "../Components/Title";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { exampleCode } from "../Mock/exampleCode";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  a11yDark,
  atomOneDarkReasonable,
} from "react-syntax-highlighter/dist/esm/styles/hljs";
import InfoParagraph from "../Components/InfoParagraph";
import "../App.css";
import {
  DevdocuCodeSnippet,
  DevdocuParagraph,
  DevdocuTypes,
} from "../Mock/rtkdocumentation";
import { mockRtkSnippets } from "../Mock/MockSnippets";
import { getElementsFromArray } from "../General/generalFunctions";
import Subheader from "../Components/Subheader";
import InformationSection from "../Components/InformationSection";
import { useSelector } from "react-redux";
import { ScreenWidth } from "../General/sizes";

var randomstring = require("randomstring");

export type DevdocuSnippet = {
  id: string;
  name: string;
  level: "basic" | "intermediate" | "complex";
  description: string;
  content: (DevdocuParagraph | DevdocuCodeSnippet)[];
};

const SNIPPETS_PAGE_LIMIT = 9;

type Props = { snippets?: DevdocuSnippet[]; queriedSnippet?: any };

export default function SnippetsSection({
  snippets = mockRtkSnippets,
  queriedSnippet = 0,
}: Props) {
  const [selectedSnippet, setSelectedSnippet] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [currentSnippet, setCurrentSnippet] = useState<DevdocuSnippet | null>(
    null
  );

  console.log("queried snippet %s", queriedSnippet);

  function handleChangePageNumber(pageNumber: number) {
    console.log(
      "🚀 ~ file: SnippetsSection.tsx:35 ~ handleChangePageNumber ~ pageNumber:",
      pageNumber
    );
    setPageNumber(pageNumber);
  }

  //Snippet query
  useEffect(() => {
    const tmp = Math.ceil(queriedSnippet / SNIPPETS_PAGE_LIMIT);
    const tmpCorrected = tmp <= 0 ? 1 : tmp;
    console.log(queriedSnippet);
    console.log(SNIPPETS_PAGE_LIMIT);
    console.log(queriedSnippet / SNIPPETS_PAGE_LIMIT);
    console.log("spawned page: %d", tmpCorrected);
    setPageNumber(tmpCorrected);
    setSelectedSnippet(queriedSnippet);

    const foundObject = snippets.find((obj) => obj.id === queriedSnippet);
    if (foundObject !== undefined) {
      setCurrentSnippet(foundObject);
    }
  }, [queriedSnippet]);

  const [visibleSnippets, setVisibleSnippets] = useState<DevdocuSnippet[]>([]);

  useEffect(() => {
    setVisibleSnippets(
      getElementsFromArray(
        snippets,
        (pageNumber - 1) * SNIPPETS_PAGE_LIMIT,
        (pageNumber - 1) * SNIPPETS_PAGE_LIMIT + (SNIPPETS_PAGE_LIMIT - 1)
      )
    );
    console.log(getElementsFromArray(snippets, 0, 10));
  }, [pageNumber]);

  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const screenWidth = useSelector((state: any) => {
    return state.rootReducer.appReducer.screenWidth.payload;
  });

  const [currentFile, setCurrentFile] = useState<File | null>(null);

  const getLineProps = (lineNumber: any) => {
    if (lineNumber === 2) {
      return { style: { background: "rgba(255,255,0,0.3)" } };
    }
    return {};
  };

  const handleOpenModal = useSelector(
    (state: any) => state.rootReducer.appReducer.handleOpenModal
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        className="dark text-foreground"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col">
                <div className="text-2xl">{currentSnippet?.name}</div>
                <div className="text-lg">{currentSnippet?.level}</div>
              </ModalHeader>
              <ModalBody>
                <div>{currentSnippet?.description}</div>
                {currentSnippet?.content.map((item) => {
                  switch (item.type) {
                    case DevdocuTypes.DEVDOCU_DEFAULTPARAGRAPH:
                      return <InfoParagraph content={item}></InfoParagraph>;
                    case DevdocuTypes.DEVDOCU_INFORMATIONPARAGRAPH:
                      return (
                        <InformationSection content={item}></InformationSection>
                      );
                    case DevdocuTypes.DEVDOCU_CODESNIPPET:
                      return (
                        <div className="relative w-full">
                          <SyntaxHighlighter
                            customStyle={{ borderRadius: "10px" }}
                            language="javascript"
                            style={atomOneDarkReasonable}
                            wrapLines
                            showLineNumbers
                            lineProps={(lineNumber) => {
                              const customStyles =
                                getLineProps(lineNumber).style || {};
                              return {
                                style: {
                                  ...customStyles,
                                  // wordBreak: "break-all",
                                  // whiteSpace: "pre-wrap",
                                },
                              };
                            }}
                          >
                            {item.code}
                          </SyntaxHighlighter>
                          <CopyToClipboard text={item.code}>
                            <Button
                              isIconOnly
                              variant="bordered"
                              size="sm"
                              className="absolute top-0 right-0 m-3"
                            >
                              <FaCopy />
                            </Button>
                          </CopyToClipboard>
                        </div>
                      );
                    default:
                      return <>Invalid Paragraph Type</>;
                  }
                })}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <div className="w-full flex flex-row justify-center pb-3">
        <Button
          onClick={() => {
            handleOpenModal("search", ["snippet"]);
          }}
          className=""
        >
          <FaSearch className="" />
          <div className="">Search Snippets</div>
        </Button>
      </div>
      <div id="main" className={`flex flex-col sm:flex-row w-full`}>
        <div id="snippets" className="flex flex-col gap-3">
          <div
            id="snippet-cards"
            className="grid gap-3 h-full grid-cols-3 lg:grid-cols-3 md:grid-cols-3  sm:grid-cols-3 grid-rows-3"
          >
            {visibleSnippets.map((snippet) => (
              <Card
                radius="lg"
                className={`${
                  snippet.id === selectedSnippet ? "ring-[2px]" : "ring-[0px]"
                } ring-blue-600 h-[15rem]`}
                isPressable
                onClick={() => {
                  setSelectedSnippet(snippet.id);
                  setCurrentSnippet(snippet);
                  if (
                    screenWidth === ScreenWidth.MOBILE ||
                    screenWidth === ScreenWidth.SM ||
                    screenWidth === ScreenWidth.MD
                  ) {
                    onOpen();
                  }
                }}
              >
                <CardBody className="flex flex-col overflow-y-hidden">
                  <div
                    id="snippet-title"
                    className="flex flex-row items-center justify-between"
                  >
                    <div id="snippet-title-text" className="flex flex-col">
                      <div className="text-small">
                        {snippet.level.toUpperCase()}
                      </div>
                      <div className=" text-medium font-bold">
                        {snippet.name}
                      </div>
                    </div>
                  </div>
                  {/* <div id="snippet-text" className="text-xs">
                  {snippet.description}
                </div> */}
                </CardBody>
                <CardFooter className="flex flex-row gap-2">
                  <Button isIconOnly variant="bordered" size="sm">
                    <FaCopy />
                  </Button>
                </CardFooter>
              </Card>
            ))}
          </div>
          <div
            id="snippets-pagination"
            className="items-center flex flex-row justify-center"
          >
            <Pagination
              total={Math.ceil(snippets.length / SNIPPETS_PAGE_LIMIT)}
              initialPage={1}
              showControls
              onChange={(pageNumber) => {
                handleChangePageNumber(pageNumber);
              }}
              page={pageNumber}
            />
          </div>
        </div>
        {!(
          screenWidth === ScreenWidth.MOBILE ||
          screenWidth === ScreenWidth.SM ||
          screenWidth === ScreenWidth.MD
        ) && (
          <div
            id="snippet-details"
            className="sm:w-[70vw] h-[80vh] overflow-y-scroll bg-neutral-900 rounded-lg ml-3"
          >
            <div className="p-3 flex flex-col gap-3">
              {selectedSnippet === "" ? (
                <Title title="Select a snippet to start" />
              ) : (
                <>
                  <Title title={currentSnippet?.name as string} />
                </>
              )}
              <span className="text-center font-bold text-2xl">
                {selectedSnippet == "" || selectedSnippet == "0"
                  ? "Select a snippet to start"
                  : null}
              </span>
              <div className="relative">
                {currentSnippet?.content.map((item) => {
                  switch (item.type) {
                    case DevdocuTypes.DEVDOCU_DEFAULTPARAGRAPH:
                      return <InfoParagraph content={item}></InfoParagraph>;
                    case DevdocuTypes.DEVDOCU_INFORMATIONPARAGRAPH:
                      return (
                        <InformationSection content={item}></InformationSection>
                      );
                    case DevdocuTypes.DEVDOCU_CODESNIPPET:
                      return (
                        <div className="relative w-full">
                          <SyntaxHighlighter
                            customStyle={{ borderRadius: "10px" }}
                            language="javascript"
                            style={atomOneDarkReasonable}
                            wrapLines
                            showLineNumbers
                            lineProps={(lineNumber) => {
                              const customStyles =
                                getLineProps(lineNumber).style || {};
                              return {
                                style: {
                                  ...customStyles,
                                  // wordBreak: "break-all",
                                  // whiteSpace: "pre-wrap",
                                },
                              };
                            }}
                          >
                            {item.code}
                          </SyntaxHighlighter>
                          <CopyToClipboard text={item.code}>
                            <Button
                              isIconOnly
                              variant="bordered"
                              size="sm"
                              className="absolute top-0 right-0 m-3"
                            >
                              <FaCopy />
                            </Button>
                          </CopyToClipboard>
                        </div>
                      );
                    default:
                      return <>Invalid Paragraph Type</>;
                  }
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
