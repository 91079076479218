import React, { useEffect } from "react";

// This component is used to listen to the shortcut Ctrl + K, or Cmd + K, and executes the passed action.

export type HotkeyListenerProps = {
    action: any,
}

export function HotkeyListener({action}: HotkeyListenerProps) {
  const handleKeyDown = (event: any) => {
    if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
      action();
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return(
    <></>
  )
}
