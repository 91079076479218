import { useState, useMemo, useEffect, useRef } from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Controls,
  MiniMap,
  Panel,
  ReactFlowInstance,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import { CustomNode } from "./CustomNode";
import CustomConnectionLine from "./CustomConnectionLine";
import ButtonEdge from "./ButtonEdge";
import {
  rtkMainEdges,
  rtkMainNodes,
} from "../Mock/rtkmainGraphData";
import debounce from "lodash.debounce";
import {
  incrementChangeCount,
  setActiveDataRoute,
  setChangesDetected,
  setPictureInPicture,
  setResizingMainGraph,
} from "../Redux/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { setDataRoutes } from "../Redux/appSlice";
import { rtkDataRoutes } from "../Mock/rtkmainGraphData";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Switch,
  Tooltip,
} from "@nextui-org/react";
import { ResizableBox } from "react-resizable";
import { NAVBAR_HEIGHT_NUM } from "../General/constants";
import {
  rtkStoreEdges,
  rtkStoreNodes,
} from "../Mock/rtkstoreGraphData";
import {
  getCollectiveNodesCenter,
  getGraphZoomLevel,
} from "../General/generalFunctions";
import {
  FaBaby,
  FaCog,
  FaExpand,
  FaExpandArrowsAlt,
  FaEyeSlash,
  FaInfo,
  FaMinus,
  FaPlus,
  FaRegEye,
  FaRegWindowRestore,
  FaWheelchair,
  FaWrench,
} from "react-icons/fa";
import { ScreenWidth } from "../General/sizes";

type Props = {
  nodes: any;
  edges: any;
  dataRoutes: any;
  isResizing: any;
  setResizing: any;
};

export default function Graph({
  nodes,
  edges,
  dataRoutes,
  isResizing,
  setResizing,
}: Props) {
  /* ----------------------------------- Sbx ---------------------------------- */
  const [nodesState, setNodesState, onNodesStateChange] = useNodesState(nodes);
  const [edgesState, setEdgesState, onEdgesStateChange] = useEdgesState(edges);
  // const [isResizing, setResizing] = useState(false);
  const [advancedMode, setAdvancedMode] = useState(true);
  const [autoSwitch, setAutoSwitch] = useState(true);
  const [canCenterGraph, setCanCenterGraph] = useState(false);

  /* ----------------------------- Local Variables ---------------------------- */
  const reactFlowWrapperRef = useRef<HTMLDivElement>(null);
  const pictureInPicture = useSelector(
    (state: any) => state.rootReducer.appReducer.pictureInPicture
  );

  /* ---------------------------------- Redux --------------------------------- */
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setDataRoutes(dataRoutes));
  }, []);

  /* --------------------------- Debouncing Changes -----F---------------------- */
  // i.e. handleNodesChange is called very frequently when a node changes its property. Wait until 0.5s after
  // the last call of change to properly tell the redux store that changes have been detected. ChangesDetected used to
  // calculate the offset of the "data" going through the edges, since this was implemented using stroke offsets.
  const hasDispatchedChange = useRef(false); // Using ref to track if changes have been dispatched

  const customHandleNodesChange = (newNodes: any) => {
    onNodesStateChange(newNodes);
    if (!hasDispatchedChange.current) {
      dispatch(setChangesDetected(true));
      hasDispatchedChange.current = true;
    }
    debouncedSetChangesToFalse();
  };

  const customHandleEdgesChange = (newEdges: any) => {
    onEdgesStateChange(newEdges);
  };

  const debouncedSetChangesToFalse = useMemo(
    () =>
      debounce(() => {
        dispatch(setChangesDetected(false));
        hasDispatchedChange.current = false; // Reset after debounce completes
      }, 500),
    [dispatch]
  );

  /* ---------- Force scroll when the user is hovering over the graph --------- */
  useEffect(() => {
    const handleScroll = (e: any) => {
      const delta = Math.sign(e.deltaY);
      window.scrollBy(0, 50 * delta);
    };
    const currentRef = reactFlowWrapperRef.current;
    if (currentRef) {
      currentRef.addEventListener("wheel", handleScroll);
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener("wheel", handleScroll);
      }
    };
  }, []);

  /* ------------------------ Edge Types and Node Types ----------------------- */
  const nodeTypes = useMemo(() => ({ customNode: CustomNode }), []);
  const edgeTypes = useMemo(() => ({ buttonedge: ButtonEdge }), []);

  function onInitReactFlow(
    reactFlowInstance: ReactFlowInstance<any, any>
  ): void {
    setReactFlowInstance(reactFlowInstance);
  }

  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
  const [zoomOnScroll, setZoomOnScroll] = useState(true);

  const focusGraph = () => {
    const nodes = reactFlowInstance.getNodes();
    const collectiveCenter = getCollectiveNodesCenter(nodes);
    const zoom =
      getGraphZoomLevel(nodes, reactFlowInstance, reactFlowWrapperRef) * 0.8;
    reactFlowInstance.setCenter(collectiveCenter.x, collectiveCenter.y, {
      zoom,
      duration: 1000,
    });
  };

  const focusNode = (id: string) => {
    if (reactFlowInstance && autoSwitch) {
      const nodes = reactFlowInstance.getNodes();
      if (nodes) {
        const nodeToFocus = getNodeById(id, nodes);
        if (nodeToFocus) {
          const zoom =
            getGraphZoomLevel(nodes, reactFlowInstance, reactFlowWrapperRef) *
            1;
          reactFlowInstance.setCenter(
            nodeToFocus.position.x,
            nodeToFocus.position.y,
            {
              zoom,
              duration: 1000,
            }
          );
        } else {
          focusGraph();
        }
      }
    }
  };

  function getNodeById(id: any, nodes: any) {
    for (const node of nodes) {
      if (node.id === id) {
        return node;
      }
    }
    return null;
  }

  useEffect(() => {
    if (reactFlowInstance) {
      setTimeout(() => {
        focusGraph();
      }, 500);
    }
  }, [advancedMode]);

  useEffect(() => {
    console.log(canCenterGraph);
  }, [canCenterGraph]);

  const currentSection = useSelector((state: any) => {
    return state.rootReducer.appReducer.highlightedSection;
  });
  useEffect(() => {
    focusNode(currentSection.payload);
  }, [currentSection]);

  const [hidden, setHidden] = useState(false);
  const [piP, setPiP] = useState(false);

  const screenWidth = useSelector((state: any) => {
    return state.rootReducer.appReducer.screenWidth.payload;
  });

  /* ------------------------------- Main Return ------------------------------ */
  return (
    <div className="bg-black">
      {!hidden && !pictureInPicture ? (
        <ResizableBox
          width={
            screenWidth === ScreenWidth.SM || screenWidth === ScreenWidth.MOBILE
              ? window.innerWidth - 30
              : window.innerWidth * 0.45
          }
          height={
            screenWidth === ScreenWidth.SM || screenWidth === ScreenWidth.MOBILE
              ? window.innerHeight / 3 - NAVBAR_HEIGHT_NUM
              : window.innerHeight - NAVBAR_HEIGHT_NUM
          }
          minConstraints={[
            screenWidth === ScreenWidth.SM || screenWidth === ScreenWidth.MOBILE
              ? window.innerWidth * 0.95
              : window.innerWidth * 0.2,
            screenWidth === ScreenWidth.SM || screenWidth === ScreenWidth.MOBILE
              ? window.innerHeight * 0.2
              : window.innerHeight - NAVBAR_HEIGHT_NUM,
          ]}
          maxConstraints={[
            screenWidth === ScreenWidth.SM || screenWidth === ScreenWidth.MOBILE
              ? window.innerWidth
              : window.innerWidth * 0.45,
            screenWidth === ScreenWidth.SM || screenWidth === ScreenWidth.MOBILE
              ? window.innerHeight * 0.7
              : window.innerHeight - NAVBAR_HEIGHT_NUM,
          ]}
          resizeHandles={
            screenWidth === ScreenWidth.SM || screenWidth === ScreenWidth.MOBILE
              ? ["s"]
              : ["w"]
          }
          onResizeStart={() => {
            setResizing(true);
            dispatch(setResizingMainGraph(true));
          }}
          onResizeStop={() => {
            setResizing(false);
            focusGraph();
            dispatch(setResizingMainGraph(false));
            setTimeout(() => {
              setCanCenterGraph(false);
            }, 1100);
            dispatch(incrementChangeCount());
          }}
          className={` ${
            screenWidth === ScreenWidth.SM || screenWidth === ScreenWidth.MOBILE
              ? isResizing
                ? "border-b-3 border-blue-500"
                : "border-b-2 border-neutral-600"
              : isResizing
              ? "border-l-3 border-blue-500"
              : "border-l-2 border-neutral-600"
          }`}
          style={{ transition: "border 0.05s ease" }}
        >
          <div className="w-full h-full" ref={reactFlowWrapperRef}>
            <svg className="w-0 h-0">
              <defs>
                <marker
                  id="devdocuArrow"
                  viewBox="0 0 10 10"
                  refX="7"
                  refY="5"
                  markerUnits="strokeWidth"
                  markerWidth="4"
                  markerHeight="4"
                  orient="auto"
                >
                  <path d="M 0 0 L 10 5 L 0 10 z" fill="#fff" />
                </marker>
              </defs>
            </svg>
            {
              <ReactFlow
                nodes={nodesState}
                edges={edgesState}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                connectionLineComponent={CustomConnectionLine}
                nodesDraggable={true}
                fitView
                fitViewOptions={{ padding: 0.1 }}
                style={{ borderRadius: "5px" }}
                onNodesChange={customHandleNodesChange}
                onEdgesChange={customHandleEdgesChange}
                zoomOnScroll={zoomOnScroll}
                onInit={onInitReactFlow}
                onMove={() => {
                  setCanCenterGraph(true);
                }}
                minZoom={0.1}
              >
                <Background variant={BackgroundVariant.Dots} color="white" />
                <Panel position="top-left" className="flex flex-row gap-3">
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-row gap-3">
                      {!(screenWidth === ScreenWidth.MOBILE) && (
                        <>
                          <Tooltip closeDelay={0} content="Hide">
                            <Button onClick={() => setHidden(true)} isIconOnly>
                              <FaEyeSlash />
                            </Button>
                          </Tooltip>

                          <Tooltip closeDelay={0} content="Picture-in-picture">
                            <Button
                              onClick={() => {
                                setPiP(true);
                                dispatch(setPictureInPicture(true));
                              }}
                              isIconOnly
                            >
                              <FaRegWindowRestore />
                            </Button>
                          </Tooltip>
                        </>
                      )}
                      <Dropdown closeOnSelect={false}>
                        <DropdownTrigger>
                          <Button isIconOnly>
                            <FaCog />
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu aria-label="Static Actions" className="">
                          <DropdownItem key="auto" className="p-1">
                            <Switch
                              defaultChecked={autoSwitch}
                              defaultSelected={autoSwitch}
                              onChange={(event: any) => {
                                setAutoSwitch(event.target.checked);
                              }}
                              size="sm"
                            >
                              Auto
                            </Switch>
                          </DropdownItem>
                          <DropdownItem key="zoomScroll" className="p-1">
                            <Switch
                              defaultChecked={zoomOnScroll}
                              defaultSelected={zoomOnScroll}
                              onChange={(event: any) => {
                                setZoomOnScroll(event.target.checked);
                              }}
                              size="sm"
                            >
                              Scroll zoom
                            </Switch>
                          </DropdownItem>
                          {screenWidth === ScreenWidth.MOBILE ? (
                            <DropdownItem key="hide" className="p-1">
                              <Button onClick={() => setHidden(true)} size="sm">
                                Hide
                              </Button>
                            </DropdownItem>
                          ) : (
                            <DropdownItem
                              key="pip"
                              className="p-0"
                            ></DropdownItem>
                          )}
                          {screenWidth === ScreenWidth.MOBILE ? (
                            <DropdownItem key="pip" className="p-1">
                              <Button
                                onClick={() => {
                                  setPiP(true);
                                  dispatch(setPictureInPicture(true));
                                }}
                                size="sm"
                              >
                                Picture-in-picture
                              </Button>
                            </DropdownItem>
                          ) : (
                            <DropdownItem
                              key="pip"
                              className="p-0"
                            ></DropdownItem>
                          )}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    <div></div>
                  </div>
                </Panel>
                <Panel
                  position={`${
                    screenWidth === ScreenWidth.SM ||
                    screenWidth === ScreenWidth.MOBILE
                      ? "bottom-right"
                      : "bottom-center"
                  }`}
                >
                  <div id="graph-zoom-controls" className="flex flex-row gap-3">
                    {screenWidth === ScreenWidth.MOBILE ? (
                      <Dropdown>
                        <DropdownTrigger>
                          <Button isIconOnly>
                            <FaWrench />
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu>
                          <DropdownItem className="p-1">
                            <Button
                              onClick={() => {
                                focusGraph();
                                setTimeout(() => {
                                  setCanCenterGraph(false);
                                }, 1100);
                              }}
                              isDisabled={!canCenterGraph}
                            >
                              <FaExpand /> Fit View
                            </Button>
                          </DropdownItem>
                          <DropdownItem className="p-1">
                            <Button
                              onClick={() => {
                                reactFlowInstance.zoomIn();
                              }}
                            >
                              <FaPlus /> Zoom in
                            </Button>
                          </DropdownItem>
                          <DropdownItem className="p-1">
                            <Button
                              onClick={() => {
                                reactFlowInstance.zoomOut();
                              }}
                            >
                              <FaMinus /> Zoom out
                            </Button>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    ) : (
                      <>
                        <Tooltip content="Fit View" closeDelay={0}>
                          <Button
                            onClick={() => {
                              focusGraph();
                              setTimeout(() => {
                                setCanCenterGraph(false);
                              }, 1100);
                            }}
                            isDisabled={!canCenterGraph}
                            isIconOnly
                          >
                            <FaExpand />
                          </Button>
                        </Tooltip>
                        <Tooltip content="Zoom In" closeDelay={0}>
                          <Button
                            onClick={() => {
                              reactFlowInstance.zoomIn();
                            }}
                            isIconOnly
                          >
                            <FaPlus />
                          </Button>
                        </Tooltip>
                        <Tooltip content="Zoom Out" closeDelay={0}>
                          <Button
                            onClick={() => {
                              reactFlowInstance.zoomOut();
                            }}
                            isIconOnly
                          >
                            <FaMinus />
                          </Button>
                        </Tooltip>
                      </>
                    )}
                  </div>
                </Panel>
              </ReactFlow>
            }
          </div>
        </ResizableBox>
      ) : (
        <Button
          className="absolute right-0 mt-3 mr-3"
          isIconOnly
          variant="bordered"
          color="primary"
          onClick={() => {
            setHidden(false);
            dispatch(setPictureInPicture(false));
          }}
        >
          <FaRegEye />
        </Button>
      )}
    </div>
  );
}
