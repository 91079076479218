import {
  Button,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Kbd,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  useDisclosure,
} from "@nextui-org/react";
import { useState } from "react";
import { FaDiscord, FaSearch, FaTwitter } from "react-icons/fa";
import { Outlet } from "react-router-dom";
import { NAVBAR_HEIGHT_PX } from "../General/constants";
import { useDispatch, useSelector } from "react-redux";
import { DevDocuLogo } from "../Components/DevDocuLogo";

import { HotkeyListener } from "../Components/HotkeyListener";
import DevdocuSearch from "../Navigation/DevdocuSearch";
import DevdocuNavbar from "../Navigation/DevdocuNavbar";
import { setHandleOpenModal } from "../Redux/appSlice";
import { ScreenWidth } from "../General/types";

type Props = {};

export default function Layout({}: Props) {
  // Modal controls
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalType, setModalType] = useState<"" | "search" | "project">("");
  const [searchItems, setSearchItems] = useState<string[]>([]);

  const handleOpenModal = (
    type: "" | "search" | "project",
    searchItems?: string[]
  ) => {
    setModalType(type);
    if (searchItems !== undefined) {
      setSearchItems(searchItems);
    }
    onOpen();
  };

  const dispatch = useDispatch();
  dispatch(setHandleOpenModal(handleOpenModal));

  const screenWidth = useSelector((state: any)=>{return state.rootReducer.appReducer.screenWidth.payload})

  return (
    <>
      <HotkeyListener
        action={() => {
          handleOpenModal("search");
        }}
      />
      <div className="dark text-foreground bg-black">
        {/* Navbar */}
        <DevdocuNavbar handleOpenModal={handleOpenModal} />

        {/* Child components of the layout here */}
        <div className="min-h-screen">
          <Outlet />
        </div>

        {/* Footer */}
        <div className="p-[5%] sm:p-10 text-xs h-auto flex-row flex flex-wrap gap-4 justify-center w-full h-auto">
          <Link
            className="text-xs text-white h-full"
            onClick={() => {
              alert(
                "🚀 ’I have no special talent. I am only passionately curious.’ -Albert Einstein"
              );
            }}
          >
            ©️Devdocu 2023
          </Link>
          <div className="flex flex-col flex-wrap">
            <Link href="/" className="text-xs">
              Home
            </Link>
            <Link href="/about" className="text-xs">
              About
            </Link>
            <Link href="/reduxtoolkit" className="text-xs">
              Redux-Toolkit
            </Link>
            <Link href="/about#contact" className="text-xs">
              Contact Form
            </Link>
          </div>
          <div className="flex flex-col flex-wrap">
            <Link href="google.com" className="text-xs">
              Terms
            </Link>
            <Link href="google.com" className="text-xs">
              Privacy
            </Link>
          </div>
        </div>
        <Modal
          backdrop={"blur"}
          isOpen={isOpen}
          onClose={onClose}
          className={`dark text-foreground `}
          size={ screenWidth===ScreenWidth.SM || screenWidth===ScreenWidth.MOBILE ? 'full' : 'xl' }
          // classNames={{base: `${modalType === 'search' ? '' : '' }`}}
          closeButton={
            <div className="">
              <Kbd>Esc</Kbd>
            </div>
          }
        >
          <ModalContent
            className={`${modalType === "search" ? "p-0" : "p-10"}`}
          >
            {(onClose) => {
              return modalType === "project" ? (
                <>
                  <ModalBody className="text-4xl flex items-center justify-center text-center">
                    <b>
                      Are you a beginner, or do you need a refresher in
                      Redux-Toolkit?
                    </b>
                  </ModalBody>
                  <ModalFooter className="flex flex-row items-center justify-center">
                    <div className="flex flex-col w-1/2 items-center justify-center">
                      <Link href="/reduxtoolkit-introduction">
                        <Button
                          color="primary"
                          onPress={() => {
                            onClose();
                          }}
                          size="lg"
                        >
                          Yes
                        </Button>
                      </Link>
                      <div className="text-sm text-center">
                        Get me up <br />
                        to speed
                      </div>
                    </div>
                    <div className="flex flex-col w-1/2 items-center justify-center">
                      <Link href="/reduxtoolkit">
                        <Button color="primary" onPress={onClose} size="lg">
                          No
                        </Button>
                      </Link>
                      <div className="text-sm text-center">
                        Take me to the
                        <br /> documentation
                      </div>
                    </div>
                  </ModalFooter>
                </>
              ) : (
                <>
                  <DevdocuSearch onModalClose={onClose} searchItems={searchItems} />
                </>
              );
            }}
          </ModalContent>
        </Modal>
      </div>
    </>
  );
}
