import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";

type Props = { child: any; initialY?: number; initialRotate?: number, delay?: number };

export default function Floaty({
  child,
  initialY,
  initialRotate,
  delay
}: Props) {
  return (
    <motion.div
      animate={{
        translateY: [-6, 6, -6],
      }}
      transition={{
        duration: 6,
        ease: "easeInOut",
        times: [0, 0.5, 1],
        repeat: Infinity,
        delay: delay,
      }}
    >
      <motion.div
        animate={{
          rotate: [-1, 1, -1],
        }}
        transition={{
          duration: 8.75,
          ease: "easeInOut",
          times: [0, 0.5, 1],
          repeat: Infinity,
          delay: delay,
        }}
      >
        {child}
      </motion.div>
    </motion.div>
  );
}
