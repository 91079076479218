import {
  Accordion,
  AccordionItem,
  Button,
  Chip,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Kbd,
  Link,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
} from "@nextui-org/react";
import { FaSearch, FaTwitter, FaDiscord } from "react-icons/fa";
import { DevDocuLogo } from "../Components/DevDocuLogo";
import { useSelector } from "react-redux";
import { NAVBAR_HEIGHT } from "../General/sizes";
import {
  NavigationSection,
  mockNavigationSection,
} from "../Mock/rtkDocumentationNavigation";
import { spacePascalCase, toPascalCase } from "../General/generalFunctions";
import { useState } from "react";

type DevdocuNavbarProps = {
  handleOpenModal: any;
  navigationItems?: NavigationSection[];
};

export default function DevdocuNavbar({
  handleOpenModal,
  navigationItems = mockNavigationSection,
}: DevdocuNavbarProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <Navbar
        isBordered
        height={NAVBAR_HEIGHT}
        className="p-1 sm:p-2"
        isMenuOpen={isMenuOpen}
      >
        <NavbarMenuToggle
          className="xl:hidden"
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
          }}
        />
        <NavbarMenu className="dark">
          {
            <div className="sm:hidden">
              <NavbarMenuItem>
                <Link href="/">Home</Link>
              </NavbarMenuItem>
              <NavbarMenuItem>
                <Link href="/about">About</Link>
              </NavbarMenuItem>
              <NavbarMenuItem>
                <Link href="/reduxtoolkit">Redux Toolkit</Link>
              </NavbarMenuItem>
              <NavbarMenuItem>
                <Link href="twitter">Twitter</Link>
              </NavbarMenuItem>
              <NavbarMenuItem>
                <Link href="discord">Discord</Link>
              </NavbarMenuItem>
              <NavbarMenuItem>
                <Divider />
              </NavbarMenuItem>
            </div>
          }
          <NavbarMenuItem>
            {navigationItems?.map((item, key) => {
              return (
                <Accordion selectionMode="multiple" isCompact variant="light">
                  <AccordionItem
                    key={key}
                    aria-label={item.sectionGroup}
                    title={spacePascalCase(toPascalCase(item.sectionGroup))}
                  >
                    <div className="flex flex-col pb-2 gap-2 pl-3">
                      {item.sectionNames.map((section, key) => {
                        return (
                          <Link
                            href={"#" + section}
                            key={key}
                            onClick={() => {
                              setIsMenuOpen(false);
                            }}
                          >
                            {spacePascalCase(toPascalCase(section))}
                          </Link>
                        );
                      })}
                    </div>
                  </AccordionItem>
                </Accordion>
              );
            })}
          </NavbarMenuItem>
        </NavbarMenu>
        <Link href="/">
          <NavbarBrand className="cursor-pointer">
            <DevDocuLogo height="25em" width="25em" />
            <p className="font-bold text-white px-4 ">Devdocu</p>
          </NavbarBrand>
        </Link>
        <>
          <NavbarContent className="hidden sm:flex gap-4" justify="start">
            <NavbarItem>
              <Link href="/" className="text-white">
                Home
              </Link>
            </NavbarItem>
            <NavbarItem>
              <Link href="/about" className="text-white">
                About
              </Link>
            </NavbarItem>
            <NavbarItem>
              <Dropdown className="dark text-foreground">
                <DropdownTrigger>
                  <Link className=" cursor-pointer text-white font-light">
                    Web Dev
                  </Link>
                </DropdownTrigger>
                <DropdownMenu aria-label="Web Dev">
                  <DropdownItem
                    key="rtk"
                    onClick={() => {
                      handleOpenModal("project");
                    }}
                  >
                    <div className="flex flex-row justify-between gap-3 items-center">
                      <div>Redux Toolkit</div>
                      <Chip color="success" variant="shadow">
                        New
                      </Chip>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavbarItem>
          </NavbarContent>
        </>
        <NavbarContent justify="end" className="">
          <>
            <Button
              onClick={() => {
                handleOpenModal("search", ['section', 'snippet', 'template']);
              }}
              className=""
            >
              <FaSearch className="" />
              <div className="">Search</div>
              <Kbd className="hidden sm:block">Cmd + K</Kbd>
            </Button>
            <div id="social-media-links" className="md:flex flex-row hidden">
              <Button variant="light" isIconOnly className=" text-lg">
                <FaTwitter />
              </Button>
              <Button variant="light" isIconOnly className=" text-lg">
                <FaDiscord />
              </Button>
            </div>
          </>
        </NavbarContent>
      </Navbar>
    </>
  );
}
