import React from "react";
import {
  DevdocuDocumentationDivider,
  DevdocuSectionType,
  DevdocuTypes,
} from "../Mock/rtkdocumentation";
import Title from "../Components/Title";
import InfoParagraph from "../Components/InfoParagraph";
import InformationSection from "../Components/InformationSection";
import Subheader from "../Components/Subheader";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDarkReasonable } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Button, Divider, Spacer } from "@nextui-org/react";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaArrowDown, FaCopy } from "react-icons/fa";
import ComponentGraph from "../Graph/ComponentGraph";
import { rtkMainEdges, rtkMainNodes } from "../Mock/rtkmainGraphData";
import { useSelector } from "react-redux";
import { ScreenWidth } from "../General/sizes";
import CodeSnippet from "../Components/CodeSnippet";

const mockSection: DevdocuSectionType = {
  type: DevdocuTypes.DEVDOCU_SECTIONTYPE,
  title: "Mock",
  sectionName: "mock",
  sectionGroup: "mockGroup",
  content: [
    {
      content: [
        {
          type: DevdocuTypes.DEVDOCU_NORMALTEXT,
          text: "Problem: ",
        },
        {
          type: DevdocuTypes.DEVDOCU_POPOVER,
          text: "Mock",
          popoverText: "This is a mock problem.",
        },
        {
          type: DevdocuTypes.DEVDOCU_NORMALTEXT,
          text: "can be hell.",
        },
      ],
      type: DevdocuTypes.DEVDOCU_DEFAULTPARAGRAPH,
    },
  ],
};

type Props = { data: DevdocuSectionType | DevdocuDocumentationDivider, last?: boolean };

export default function DevdocuSection({ data = mockSection, last = false }: Props) {

  const screenWidth = useSelector((state: any)=>{return state.rootReducer.appReducer.screenWidth.payload})

  return (
    <>
    {
      (data.type === DevdocuTypes.DEVDOCU_SECTIONTYPE) ?
    <section id={data.sectionName} className="navbar-scroll-offset flex flex-col gap-3">
      <Title title={data.title} />
      {data.content.map((item) => {
        switch (item.type) {
          case DevdocuTypes.DEVDOCU_DEFAULTPARAGRAPH:
            return <InfoParagraph content={item}></InfoParagraph>;
          case DevdocuTypes.DEVDOCU_INFORMATIONPARAGRAPH:
          return <InformationSection content={item}></InformationSection>;
          case DevdocuTypes.DEVDOCU_HEADER:
            return <Subheader text={item.header} className="pt-6" descriptors={item.descriptors}/>;
          case DevdocuTypes.DEVDOCU_CODESNIPPET:
            return (
              <CodeSnippet code={item.code}/>
            );
          case DevdocuTypes.DEVDOCU_SMALLGRAPH:
            return(
              <ComponentGraph nodes={item.nodes} edges={item.edges}/>
            )
          default:
            return <>Invalid Paragraph Type</>;
        }
      })}
    </section>
    : 
    <section>
      <Spacer y={3}/>
      <Divider/>
      <Spacer y={3}/>
    </section>
    } 
    </>
  );
}
